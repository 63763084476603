/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
/* eslint-disable object-shorthand */
import React, { useState, useEffect} from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import FormLabel from "@mui/material/FormLabel";
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
// for print user in consol
import axios from "axios";

import clsx from "clsx";
import { useHistory } from "react-router-dom";
// import Table from "../../components/Table/Table";
import Table from "../../components/Table1/Table1";

import styles from "./InvoiceUpload.module.css";
import { CustomFormGroup, CustomSelect } from "../../components/FormComponents";
import BackButton from "../../components/BackButton/BackButton";
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex,
} from "../../constants";
import { withAllowedOperationsProvider } from "../../hocs";
import { CustomTab, Button, AuthChecker } from "../../atomicComponents";
import { API } from "../../apis/api";
import { buildErrorMessage } from "../../apis/calls";
import { usePopupManager } from "../../providers/PopupManager/PopupManager";
import { GridLoadingSpinner } from "../../components";
import StepBar1 from '../../components/StepBar1/StepBar';
// import { error } from "jquery";

const VendarData = () => {


  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  const auth = useSelector((state) => state.auth); // useSelector
  // console.log('auth in Supplier: ', auth);
  const { userRole, userID, userName : username } = auth
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [checkDisable, setDisable] = useState(true);
  const [Bc, setBC] = useState(null);
  const [projectCode, setProjectCode] = useState(null)
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [asnCount, setAsnCount] = useState(0);
  const [Fy, setFY] = useState(null)
  const [fyArray, setFyArray] = useState([])
  const [quarter, setQuarter] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [latestProject, setLatestProject] = useState("")
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [recentProjectCodeDescription, setRecentProjectCodeDescription] = useState()
  const [tlmbslSubmit, setTmlbslSubmit] = useState(null)

  const [pliSubmissionStatus, setPliSubmissionStatus] = useState({})
  console.log('pliSubmissionStatus: ', pliSubmissionStatus)  

  const [selectedRow, setSelectedRow] = useState([]); 
  const handleOpen1 = (selected) => {setIsPopupOpen1(true); setSelectedRow(selected)};
  const [isPopupOpen1, setIsPopupOpen1] = useState(false); 
  const handleClose1 = () => setIsPopupOpen1(false);
  const [selectedRows, setSelectedRows] = useState([]);
console.log("selectedRowsselectedRows", selectedRows);
// const [comment, setComment] = useState(""); // Store the comment entered by the user


const [dataList, setDataList] = useState([]);

  const [loading, setLoader] = useState(false);
  const [loader, setLoading] = useState(true);
  const [vendCode, setvendCode] = useState();
  const [datesData, setDates] = useState({
    endDate: "",
    startDate: "",
  });

  // 
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
  const handleOpenFormModal = (selected) => {
    setSelectedRows(selected);
    setPopupOpenFormModal(true);
  }
  const handleCloseFormModal = (selected) => {
    setSelectedRows(selected);
    setPopupOpenFormModal(false);
    // setComment(""); // Reset the comment when the popup is closed
  }

  

  const ROW_HEIGHT = 38;
  const [isValidatePopupOpen, setIsValidatePopupOpen] = useState(false)
  const handleOpenValidatePopup = (selected) => {
    setSelectedRows(selected);
    setIsValidatePopupOpen(true)
  }
  const handleCloseValidatePopup = (selected) => {
    setIsValidatePopupOpen(false)
    setSelectedRows(selected);
  }

  const [isSubmissionPopupOpen, setIsSubmissionPopupOpen] = useState(false)
  const handleOpenSubmissionPopup = () => {setIsSubmissionPopupOpen(true)}
  const handleCloseSubmissionPopup = () => {setIsSubmissionPopupOpen(false)}

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () => {
    // uploadFileData();
  };

  const redirectToApplicationLandingPage = () => {
    history.push("/");
  };

  const setCount = (count) => {
    setAsnCount(count);
  };
  // SubmitStatusUpdate

  const isAdminUser = userRole?.includes('admin')
  const isGDCUser = userRole?.includes('TMLBSL')
  const isPurchaseUser = userRole?.includes('Purchase')
  const isVendorUser = userRole?.includes('Vendor')
  const isPMTUser = userRole?.includes('PMT')
  const isProductLineUser = userRole?.includes('Product-line')
  const isBOEUser = userRole?.includes('BOE')

 useEffect(() => {
    // getData()
    getRecentProjectCode()
    getAllProjectCode()
    // getdate();
    //  Uploadcertification();
  }, []);
  
  useEffect(() => {
    if (selectedProjectCode) {
      // Fetch suppliers related to the selected project code
      getData(selectedProjectCode);  
    }
  }, [selectedProjectCode]);
  
  // Handle dropdown changes
  const handleDropdownChange = (selectedOption, name) => {
    if (name === 'Project_Code') {
      // Update the selected project code
      setSelectedProjectCode(selectedOption);
      // setBC(selectedOption);
    }
    else if (name === 'Supplier') {
      // Update the selected supplier
      setBC(selectedOption);
    }
  };

  const apiBody = {
    Project_code: projectCode?.value,
    Supplier_code: Bc?.value,
    Plant_code: plant?.value,
    Financial_year: Fy?.value,
    Quarter: quarter?.value
    }
    useEffect(() => {

    }, [bcArray])
    console.log('Supplier BC: ', Bc);
  const getRecentProjectCode = async (projectCodeOptions) => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(`${baseUrl}LatestProjectCodeforProductline/`);
      if (status !== 200) {
        throw new Error("Failed to fetch the latest project code");
      }
      console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code,
      }
      setLatestProject(recentProj)
      setSelectedProjectCode(recentProj) // Automatically select the latest project code
      setProjectCode(recentProj)
      // getVcForProjectCode(recentProj)
      // const recentProjectCodeDescription = 
      getData(recentProj)
    } catch (error) {
      // console.error("Error fetching recent project codes:", error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!',
      });
    }
  }

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      const { data } = await axios.get(`${baseUrl}AllProjectCodeforProductline/`);
      const allProjectCodes = data.map(({ Project_code }) => Project_code);
      const uniqueProjects = [...new Set(allProjectCodes)];
      const projectCodeOptions = uniqueProjects.map((value) => ({
        value,
        label: value,
      }));

      setProjectCodeArray(projectCodeOptions)
      // const projectDesc = uniqueProjects?.maps((item) => ( item?.Project_code === latestProject))
      const projectDesc = uniqueProjects.map((item) => { 
        console.log('item: ', item); 
        return item?.Project_code === latestProject})
      console.log('projectDesc: ', projectDesc)
      console.log('projectdesc latest: ', latestProject, data)
      // const lastElement = array[array.length - 1];
      const latestProjectCodeDesc = data[data?.length - 1]?.Project_code_Description
      console.log('projectDesc last: ', latestProjectCodeDesc);
      setRecentProjectCodeDescription(latestProjectCodeDesc)
      // await getRecentProjectCode(projectCodeOptions);
      // const recentProjectCodeDescription = 
    } catch (error) {
      console.error("Error fetching project codes:", error);
    }
  }

  

  const showTable = () => {
    // const { userRole } = auth
    if (userRole[0] === 'admin' || userRole[0] === 'TMLBSL' || userRole[0] === 'GDC' || userRole[0] === 'Purchase') {
      if (projectCode === null || projectCode.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: "Project Code is Mandatory",
        })
      } else {
       
        getTableData();
        show(true);
      }

    } else if (userRole[0] === 'Vendor') {
      if (projectCode === null || projectCode.value === '') {
        // if (selectedProjectCode === null || selectedProjectCode.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: "Project Code is Mandatory",
        })
      } else if (Bc === null || Bc.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: "Supplier Code is Mandatory",
        })

      }
      else if (plant === null || plant.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: "Plant Code is Mandatory",
        })
      } else {
        
        getTableData();
        show(true);
      }

    }
    else {
    
      getTableData();
      show(true);

    }


  };

  

 
  // let Vendor_Code ='';
  const handleSelect = (selectedOption, name) => {
    // alert(selectedOption.value);
    // console.log(selectedOption);
    setvendCode(selectedOption.value);
    if (name == "VC") {
      show(false);

      setBC(selectedOption);
      // getPlantData(selectedOption)
    } else {
      setPlant(selectedOption);
    }
  };


const getTableData = () => {
    const apiBody1 = {
        // Project_code: projectCode?.value,
        Project_code: selectedProjectCode?.value,
        Supplier_code: Bc?.value,
        Po_Plant: plant?.value,
    };
    const apiFormatedDataJson = JSON.stringify(apiBody1);
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
  
    const url = auth.userRole[0] === 'Vendor' ? 'InvoiceShowData' : 'InvoiceShowData';
  
    fetch(`${baseUrl}${url}/`, {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseJson) => {
        console.log('API response:', responseJson);
  
        // Validate that the response is an array
        if (Array.isArray(responseJson)) {
          // Set the table data to the response
          setTableArray(responseJson);
        } else {
          console.error('Unexpected response format:', responseJson);
          setTableArray([]); // Set empty array if response format is incorrect
        }
  
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching table data:', error);
        setLoading(false);
      });
  };
  


  console.log('latestProject: ', latestProject?.value);
  const getData = (recentProj) => {
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDvaCollection' : 'SupplierDvaCollectionAdminGDC'
    const url = auth.userRole[0] === 'Vendor' ? 'InvoiceShowData' : 'SupplierDvaCollectionAdminGDCshow'
    const formattedBody = {
      // Project_code: latestProject?.value
      Project_code: recentProj?.value
    }
    // fetch(`${baseUrl}SupplierDvaCollection/`, {
    fetch(`${baseUrl}LoadVendorcodeandplantwrtprojectcode/`, {
      method: "POST",
      headers: headerss,
      body: JSON.stringify(formattedBody)
      // body: formattedBody
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('responseJson: ', responseJson);
      // Extract unique values for different fields
        const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_code))].filter(Boolean);
        const uniquePlants = [...new Set(responseJson.map(item => item.Po_Plant))].filter(Boolean);
        const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))].filter(Boolean);
        const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))].filter(Boolean);

        // Extract unique values for different fields
        // const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_Code))];
        // const uniqueProjects = [...new Set(responseJson.map(item => item.Project_code))];
        // const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))];
        // const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))];
        // const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))];
        console.log('Filtered response1: ', uniqueSuppliers)
        console.log('Filtered response2: ', uniquePlants)
        console.log('Filtered response3: ', uniqueQuarters)
        console.log('Filtered response4: ', uniqueYears)

        let temp = []
        if (bcArray.length === 0) {
          // setBcArray(uniqueSuppliers.map(value => ({ value, label: value })));
          console.log('MApping vendor: ', uniqueSuppliers.map(value => ({ value, label: value })))
          temp = uniqueSuppliers.map(value => ({ value, label: value }))
          setBcArray(uniqueSuppliers.map(value => ({ value, label: value })));
        }
        console.log('Mapping bcArray:', bcArray, 'temp: ', temp)
        // setProjectCodeArray(uniqueProjects.map(value => ({ value, label: value })));
        setBcArray(uniqueSuppliers.map(value => ({ value, label: value })));
        console.log(projectCodeArray)
        setPlantArray(uniquePlants.map(value => ({ value, label: value })));
        setQuarterArray(uniqueQuarters.map(value => ({ value, label: value })));
        setFyArray(uniqueYears.map(value => ({ value, label: value })));

        // Extract userRole and userID from auth object
        // const { userRole, userID } = auth;
        console.log('User Role:', userRole, 'User ID:', userID);

        // Check user role to conditionally set BC state
        if (userRole.includes("Vendor-CV") || userRole.includes("Vendor")) {
          const singleVendor = bcArray.find(item => item.value === userID);
          const singleVendor1 = temp.find(item => item.value === userID);
          console.log('singleVendor: ', singleVendor, singleVendor1);
          if (singleVendor1) {
            setBC(singleVendor1);
            setDisable(true); // Disable UI elements
          }
        } else {
          setDisable(false); // Enable UI elements
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        // Handle error (e.g., show error message)
      });
  };

  

  // const uploadFileData = () => {
  //   var input = document.createElement("input");
  //   input.type = "file";
  //   input.id = "file";
  //   input.setAttribute("accept", "application/pdf"); // Only allow PDF files
  //   input.click();
  //   input.onchange = (e) => {
  //     const selectedFile = e.target.files[0];
  
  //     if (!selectedFile) {
  //       showPopup({
  //         type: MESSAGE_TYPE.ERROR,
  //         contextText: MESSAGE_TYPE.ERROR,
  //         info: "No file selected",
  //       });
  //       return;
  //     }
  
  //     if (selectedFile.type !== "application/pdf") {
  //       showPopup({
  //         type: MESSAGE_TYPE.ERROR,
  //         contextText: MESSAGE_TYPE.ERROR,
  //         info: "Only PDF files are allowed",
  //       });
  //       return;
  //     }
  
  //     setLoader(true);
  
  //     const formData = new FormData();
  //     formData.append("file", selectedFile);
  
  //     fetch(`${baseUrl}InvoiceUploadToS3/`, {
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((response) => {
  //         let status1 = parseInt(response.status, 10);
  //         if (status1 === 201) {
  //           getTableData();
  //           showPopup({
  //             type: MESSAGE_TYPE.SUCCESS,
  //             contextText: MESSAGE_TYPE.SUCCESS,
  //             info: "File uploaded successfully",
  //           });
  //         } else {
  //           showPopup({
  //             type: MESSAGE_TYPE.ERROR,
  //             contextText: MESSAGE_TYPE.ERROR,
  //             info: "File upload failed",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading file:", error);
  //         showPopup({
  //           type: MESSAGE_TYPE.ERROR,
  //           contextText: MESSAGE_TYPE.ERROR,
  //           info: "An error occurred while uploading the file",
  //         });
  //       })
  //       .finally(() => {
  //         setLoader(false);
  //       });
  //   };
  // };
  

  

  const handleFileUpload = (row) => {
    const input = document.createElement("input");
    input.type = "file";
    input.setAttribute("accept", "application/pdf");
  
    input.click();
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append("files", file);
      formData.append("Project_code", row.Project_code);
      formData.append("Part_no", row.Part_no);
      formData.append("Supplier_code", Bc?.value);
      formData.append("Po_Plant",  row.Po_Plant,);
      
      setLoader(true); // Start loader
  
      fetch(`${baseUrl}InvoiceUploadToS3/`, {
        method: "POST", // Explicitly set the method
        headers: {
          Accept: "application/json", // Expect a JSON response
        },
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((responseJson) => {
          if (responseJson.urls && responseJson.urls.length > 0) {
            const uploadedFile = responseJson.urls[0];
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: `File uploaded successfully: ${uploadedFile.filename}`,
            });
  
            // Automatically refresh the table data after successful upload
            showTable();
          } else {
            showPopup({
              type: MESSAGE_TYPE.ERROR,
              contextText: MESSAGE_TYPE.ERROR,
              info: "File uploaded successfully, but no URL was returned.",
            });
          }
        })
        .catch((error) => {
          console.error("Upload Error:", error);
          showPopup({
            type: MESSAGE_TYPE.ERROR,
            contextText: MESSAGE_TYPE.ERROR,
            info: "An error occurred while uploading the file",
          });
        })
        .finally(() => {
          setLoader(false); // Stop loader after success or error
        });
    };
  };
  
  
  
  // console.log("Project Code Before11",selectedProjectCode?.value)

const handleFileDownload = async (row, event) => {
  if (event) event.preventDefault(); // Prevent default behavior of the event

  try {
    // Payload to be sent in the request body
    const payload = {
      file_name: row.fileName,
      // Project_code: projectCode?.value,
      Project_code: row.Project_code,
      Part_no: row.Part_no,
      Supplier_code: row.Supplier_code,
      // Po_Plant: plant?.value,
      Po_Plant: row.Po_Plant}

    // Make the API call using axios
    const response = await axios({
      method: "POST",
      url: `${baseUrl}InvoiceDownloadFromS3/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    });

    // Handle successful response
    const { data } = response;
    const fileUrl = data?.url; // File URL from the response

    if (fileUrl) {
      // Extract file name from headers or fallback to the provided name
      const filename =
        response.headers["x-filename"] || row.fileName || "downloaded_file";

      // Create a temporary link element for file download
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename; // Set the extracted or default file name
      document.body.appendChild(link);
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up the DOM

      // Show success message
      showPopup({
        type: MESSAGE_TYPE.SUCCESS,
        contextText: MESSAGE_TYPE.SUCCESS,
        info: "File downloaded successfully!",
      });
    } else {
      // If the file URL is not provided
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: `No file available for Part No: ${row.Part_no}.`,
      });
    }
  } catch (error) {
    console.error("Download Error:", error);

    // Handle errors based on response status or general errors
    let errorMessage = "Document download failed.";
    if (error.response) {
      switch (error.response.status) {
        case 400:
          errorMessage = "Bad Request.";
          break;
        case 401:
          errorMessage = "Unauthorized access.";
          break;
        case 403:
          errorMessage = "Forbidden access.";
          break;
        case 404:
          errorMessage = `No file found for Part No: ${row.Part_no}.`;
          break;
        case 500:
          errorMessage = "Internal Server Error.";
          break;
        default:
          errorMessage = `Error: ${error.response.statusText}`;
      }
    } else if (error.request) {
      errorMessage = "No response received from the server.";
    } else {
      errorMessage = `Error: ${error.message}`;
    }

    // Show error message in a popup
    showPopup({
      type: MESSAGE_TYPE.FAILURE,
      contextText: MESSAGE_TYPE.FAILURE,
      info: errorMessage,
    });
  }
};

const baseDefaultColumns = [
    // {
    //   width: 200,
    //   title: "Project Code",
    //   field: "Project_code",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: "Part No.",
      field: "Part_no",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 250,
    //   title: "File Name",
    //   field: "Test3",
    //   render: (row) => (row.Test3 ? row.Test3 : "No file uploaded"),
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 430,
      title: "File Name",
      field: "Test3",
      render: (row) => (
        <span
          style={{
            color: row.Test3 ? "green" : "red",
          }}
        >
          {row.Test3 ? row.Test3 : "No file uploaded"}
        </span>
      ),
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "Action",
      field: "uploadButton",
      render: (row) => (
        <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
          {/* Upload Button */}
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={() => handleFileUpload(row)}
            data-testid="upload-action"
            style={{
              padding: "4px",
              minWidth: "30px",
              height: "30px",
              backgroundColor: "#4CAF50",
              borderColor: "#4CAF50",
              color: "#ffffff",
              cursor: "pointer",
            }}
            title={row.fileName ? "Re-upload File" : "Upload Invoice File"}
          >
            <UploadIcon style={{ fontSize: "18px" }} />
          </Button>
  
          {/* Download Button */}
          <Button
            type="button"
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              handleFileDownload(row, e);
            //   handleFileDownload(row.fileName,row.Part_no, projectCode?.value);
            }}
            data-testid="download-action"
            style={{
              padding: "4px",
              minWidth: "30px",
              height: "30px",
              backgroundColor: "#0078d4",
              borderColor: "#0078d4",
              color: "#ffffff",
              // cursor: row.fileUrl ? "pointer" : "not-allowed",
              opacity: row.fileUrl ? 1 : 0.6,
            }}
            title="Download Invoice File"
            // disabled={!row.fileUrl}
          >
            <DownloadIcon style={{ fontSize: "18px" }} />
          </Button>
        </div>
      ),
      enableSearch: true,
      enableFilter: true,
    },
  ];
  

  
  

const baseDefaultColumnsGDC = [
   
    {
      width: 200,
      title: "Part No.",
      field: "Part_no",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 250,
    //   title: "File Name",
    //   field: "Test3",
    //   render: (row) => (row.Test3 ? row.Test3 : "No file uploaded"),
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 430,
      title: "File Name",
      field: "Test3",
      render: (row) => (
        <span
          style={{
            color: row.Test3 ? "green" : "red",
          }}
        >
          {row.Test3 ? row.Test3 : "No file uploaded"}
        </span>
      ),
      enableSearch: true,
      enableFilter: true,
    },
    
    {
        width: 150,
        title: "Download",
        field: "download",
        render: (row) => (
          
          <Button
          type="button"
          className={clsx(styles.actionButton, styles.primaryActionButton)}
          variant="primary"
          onClick={(e) => {
             handleFileDownload(row, e);
          }}
          data-testid="download-action"
          style={{
            padding: "2px",
            minWidth: "28px",
            height: "28px",
            margin: "4px 0",
            backgroundColor: "#0078d4",
            borderColor: "#0078d4",
            color: "#ffffff",
            opacity: row.fileUrl ? 1 : 0.6,
          }}
          title="Invoice Download File"
        >
          <DownloadIcon style={{ fontSize: "18px" }} />
        </Button>
        ),
        enableSearch: true,
        enableFilter: true,
      },
      
    
  ];
 
const validationAPIFunction = async () => {
  let url;
  const dte = new Date();
  const formattedDate = dte.toLocaleDateString('en-GB').replace(/\//g, '-');
  console.log("username", username);
  console.log('Approve button clicked!');
  console.log('Selected Rows before submission:', selectedRows); 

  let apiBodyValidated = {
    dva_id: selectedRows.map(row => row.id).filter(id => id) 
  };

  if (apiBodyValidated.dva_id.length === 0) {
    console.error("No valid dva_id found!");
    showPopup({
      type: MESSAGE_TYPE.FAILURE,
      contextText: MESSAGE_TYPE.FAILURE,
      info: 'No valid dva_id found!',
    });
    return;
  }

  if (userRole?.includes("Purchase")) {
    apiBodyValidated = {
      ...apiBodyValidated, 
      Purchase_submitted_Name: username,
      Purchase_submitted_Date: formattedDate,
    };
    url = 'PurchaseSubmissionstatus';
  } else if (userRole?.includes('TMLBSL')) {
    apiBodyValidated = {
      ...apiBodyValidated, 
      TMLBSL_submitted_Name: username,
      TMLBSL_submitted_Date: formattedDate,
    };
    url = 'TMLBSLSubmissionstatus';
  }

  const apiFormattedDataJson = JSON.stringify(apiBodyValidated);
  const headerss = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios.post(`${baseUrl}${url}/`, apiFormattedDataJson, { headers: headerss });
    console.log('response from axios: ', response);

    if (response.data?.ok || response?.status === 200) {
      showPopup({
        type: MESSAGE_TYPE.SUCCESS,
        contextText: MESSAGE_TYPE.SUCCESS,
        info: 'Data validation successful!',
      });
    } else {
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Data validation failed!',
      });
    }

    history.push('/');
  } catch (error) {
    console.error('API error: ', error);
    showPopup({
      type: MESSAGE_TYPE.FAILURE,
      contextText: MESSAGE_TYPE.FAILURE,
      info: 'An error occurred during data validation!',
    });
  }
};

const goNextForm = async (comment) => {
    let url;
    const dte = new Date();
    const formattedDate = dte.toLocaleDateString('en-GB').replace(/\//g, '-');
  
    console.log('Selected Rows before sending:', selectedRows);
  
   
    let apiBody1 = {
      dva_id: selectedRows.map(row => row.id).filter(id => id) ,
      // TMLBSL_approve_reject_remark: comment // Add the comment (remark) to the payload
    };
  
   
    if (apiBody1.dva_id.length === 0) {
      console.error("No valid dva_id found!");
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'No valid dva_id found!',
      });
      return;
    }
  
    
    if (userRole?.includes("Purchase")) {
      apiBody1 = {
        ...apiBody1, 
        Purchase_approve_reject_remark: comment,
        Purchase_submitted_Name: formattedDate, 
        Purchase_submitted_Date: username, 
      };
      url = 'SendBackPurchaseStatusForSupplierDva'; 
    } else if (userRole?.includes('TMLBSL')) {
      apiBody1 = {
        ...apiBody1, 
        TMLBSL_approve_reject_remark: comment,
        TMLBSL_submitted_Name: formattedDate, 
        TMLBSL_submitted_Date: username, 
       
        
      };
      url = 'SendBackTMLBSLStatusForSupplierDva'; 
    }
  
    
    const apiFormattedDataJson = JSON.stringify(apiBody1);
    const headerss = {
      'Content-Type': 'application/json'
    };
  
    
    try {
      const response = await axios.post(`${baseUrl}${url}/`, apiFormattedDataJson, { headers: headerss });
      console.log('response from axios: ', response);
  
     
      if (response.data?.ok || response?.data?.status === 'Records updated successfully' || response?.status === 200) {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data sent back for Re-Submission',
        });
      } else {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Could not send data for Re-Submission!',
        });
      }
  
     
      history.push('/');
    } catch (error) {
      console.error('API error: ', error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'An error occurred while sending data for Re-Submission!',
      });
    }
  };

  const formattedCurrentDate = new Date().toLocaleDateString('en-GB').split('/').join('/')
  const submitSupplierDVACollection = async () => {
    const newAPIBody = {
      ...apiBody,
      Vendor_submitted_Date: formattedCurrentDate
    }
    const apiFormattedDataJson = JSON.stringify(newAPIBody);
    const headerss = {
      'Content-Type': 'application/json'
    };
  
    try {
      // const response = await axios.post(`${baseUrl}SubmitStatusUpdate/`, apiFormattedDataJson, { headers: headerss });
      const response = await axios.post(`${baseUrl}SupplierSubmissionstatus/`, apiFormattedDataJson, { headers: headerss });
      
      // Handle the response
      console.log("responseJson", response.data);
  
      showPopup({
        type: MESSAGE_TYPE.SUCCESS,
        contextText: MESSAGE_TYPE.SUCCESS,
        info: "Supplier DVA Submitted Successfully!",
      })
      history.push('/')
    } catch (error) {
      console.error('API error:', error);
      
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'An error occurred while submitting Supplier DVA!'
      });
    }
  };
  

  const redirectToCreateProjectPage = () => history.push("/");
  const renderHTML = () => (
    <div className={styles.formGroup}>
 {
        loading && (
          <div className={styles.gridLoader}>
            <GridLoadingSpinner />
          </div>
        )
      }
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left" }}
        columns={12}
      >
        <Grid
          item
          // sm={12}
          md={3}
          lg={3}
          // style={{ marginTop: "5px", marginBottom: '5px' }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '54%' }}>
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Project Code'
                options={projectCodeArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                // value={projectCode}
                value={selectedProjectCode}
                defaultValue={latestProject}
                isMulti={false}
                isMandatory={
                  auth.userRole[0] === 'Vendor' || auth.userRole[0] === 'admin'
                }
                isClearable
                onChange={selectedOption => {
                  // // handleSelect(e, "VC");
                  // setProjectCode(selectedOption)
                  // setSelectedProjectCode(selectedOption)
                  // // getData(selectedOption)
                 
                  setProjectCode(selectedOption)
                  setSelectedProjectCode(selectedOption); // Update selected project code
                  setBC(null); // Reset supplier value
                  // getData(selectedOption)
                  setBcArray([]); // Clear supplier array
                }}
              />
         </div>
          </div>
        </Grid>

        <Grid 
          item 
          md={3} 
          lg={3} 
          // sm={12} 
          // style={{ marginTop: "5px", marginBottom: '5px' }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "33%" }}>
              <label className={styles.label}>Supplier</label>
            </div>
            <div className={styles.formDiv} >
              <CustomSelect
                name="bc"
                options={bcArray}
                isDisabled={checkDisable}
                isMandatory={auth.userRole[0] === 'Vendor'}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                isClearable
                // onChange={(selectedOption) => {
                //   // handleSelect(e, "VC");
                //   setBC(selectedOption)
                // }}
                onChange={selectedOption => handleDropdownChange(selectedOption, 'Supplier')}
              />
            </div>
          </div>
        </Grid>

        <Grid
          item
          md={3}
          lg={3}
          // sm={12}
          // style={{ marginTop: '5px', marginBottom: '5px' }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '22%' }}>
              <label className={styles.label}>Plant</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Plant'
                options={plantArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={plant}
                isMulti={false}
                isClearable
                isMandatory={auth.userRole[0] === 'Vendor'}
                onChange={selectedOption => {
                  // handleSelect(e, "VC");
                  setPlant(selectedOption)
                }}
              />
            </div>
          </div>
        </Grid>

       

      <Grid
        item
        md={3}
        lg={3}
        // sm={12}
        // style={{ marginTop: '5px', marginBottom: '5px' }}
      >
        <div
          style={{
            marginLeft: '5px',
            paddingRight: '',
            marginTop: '1px',
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          <AuthChecker operation='search'>
            {isAuthorized => (
              <Button
                className={clsx(styles.actionButton, styles.primaryActionButton)}
                style={{ marginRight: '12px !important' }}
                variant='primary'
                //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                onClick={showTable}
                disabled={!isAuthorized}
                data-testid='confirm-action'
              >
                Show Data
              </Button>
            )}
          </AuthChecker>
         
        </div>
      </Grid>


      </Grid>

    </div>
  );


    const secondaryActions = [
    // {
    //   name: "Upload",
    //   authOperation: "upload",
    //   shouldEnable: (selected) => true,
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
    //   actionFn: (selected) => {
    //     // handleOpen();
    //     uploadDocument()
    //   },
    //   customClass: "",
    // },
    
  ]
  const VendorSubmitStatus1 = pliSubmissionStatus?.Vendor_submitted_status;
  const primaryActions = [
    // {
    //   name: "Submit Data",
    //   authOperation: "submit",
    //   shouldEnable: () => !isSubmitDataDisabled,  
    //   actionFn: handleOpenSubmissionPopup,
    //   // shouldEnable: selected => selected.length >=1,
    //   // actionFn: selected => {  handleOpen1(selected)}
      
    // },
  ]
  function submitButtonFlag() {
    const TMLBSLSubmitStatus = pliSubmissionStatus?.TMLBSL_submitted_status;
    const PurchaseSubmitStatus = pliSubmissionStatus?.Purchase_submitted_status;
   const VendorSubmitStatus = pliSubmissionStatus?.Vendor_submitted_status;
  
    const isSubmitDataDisabled =
    //  (TMLBSLSubmitStatus === 'Submitted' || PurchaseSubmitStatus === 'Submitted' || VendorSubmitStatus === 'Submitted' );
     ( VendorSubmitStatus === 'Submitted');
   return {
      isSubmitDataDisabled
    };
  }
  
  // Destructure the flag for Submit Data
  const { isSubmitDataDisabled } = submitButtonFlag();
  
  const handleSecondaryActionOnRole = () => {
    if (auth.userRole.includes('admin')) {
      return secondaryActions;
    }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL') || auth.userRole.includes('Purchase')) {
      return secondaryActions.filter((item) => item.name === 'Download Documents' || item.name === 'Download Certificate'|| item.name === 'Edit');
    }
    if (auth.userRole.includes('Vendor')) {
      return secondaryActions.filter((item) => item.name !== 'Download Documents' && item.name !== 'Download Certificate');
    }
    return secondaryActions;
  };
  const handlePrimaryActionOnRole = () => {
    if (auth.userRole.includes('Vendor')) {
      return primaryActions.filter((item) => item.name === 'Submit Data')
    }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL') || auth.userRole.includes('Purchase') || auth.userRole.includes('admin')) {
      return primaryActions.filter((item) => item.name === 'Approve' || item.name === 'Reject')
    }
    return primaryActions
  }


const handleRowSelect = (row) => {
  console.log("row", row);
  const rowId = row.id; 
  setSelectedRows(prevSelectedRows => {
    const newSelectedRows = prevSelectedRows.includes(rowId)
      ? prevSelectedRows.filter(id => id !== rowId) 
      : [...prevSelectedRows, rowId]; 

    return newSelectedRows; 
  });
};
// const selectedRowData = tableArray.filter(row => selectedRows.includes(row.id)); // Define selectedRowData based on selected row IDs

  console.log('tableArray',tableArray);

 return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper} style={{ marginTop: '10px' }}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
            // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div className={styles.processHeader} style={{ display: 'flex', height: '3em' }}>
                  <div style={{ width: "100%" }}>
                    <CustomTab title="Invoice Upload" withOutCount isSelected />
                  </div>

                  {/* <StepBar1 enable={5} /> */}

                </div>
              </div>
            </div>


          </div>


        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: "86%", padding: "0 2em" }}
          >
            <Table
              // columns={[...baseDefaultColumns]}
              // defaultColumns={[...baseDefaultColumns]}
              columns={!auth.userRole.includes('Vendor') ? [...baseDefaultColumnsGDC] : [...baseDefaultColumns]}
              defaultColumns={!auth.userRole.includes('Vendor') ? [...baseDefaultColumnsGDC] : [...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={handlePrimaryActionOnRole()}
              secondaryActions={handleSecondaryActionOnRole()}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={(e)=>{console.log("eeeeeeee", e);handleRowSelect(e)}} 
              selectedRows={selectedRows}  
               removeRowSelection={false}   // Allow row selection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
      <ValidationModal
        isValidatePopupOpen={isValidatePopupOpen}
        handleCloseValidatePopup={handleCloseValidatePopup}
        validationAPIFunction={validationAPIFunction}
      />
      <SubmitDataModal
        isSubmissionPopupOpen={isSubmissionPopupOpen}
        handleCloseSubmissionPopup={handleCloseSubmissionPopup}
        submitSupplierDVACollection={submitSupplierDVACollection}
      />
    </>
  );
};
function ValidationModal({ isValidatePopupOpen, handleCloseValidatePopup, validationAPIFunction }) {
  return (
    <Dialog
      open={isValidatePopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Validation</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be validated.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseValidatePopup}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseValidatePopup()
              validationAPIFunction()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function SubmitDataModal({ isSubmissionPopupOpen, handleCloseSubmissionPopup, submitSupplierDVACollection }) {
  return (
    <Dialog
      open={isSubmissionPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Submission</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be Submitted.</p>
            <p>Are you sure ?</p>
            
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseSubmissionPopup}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseSubmissionPopup()
              submitSupplierDVACollection()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}


function ResetModalF({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  // State to store the comment input
  const [comment, setComment] = useState('');
console.log("comment",comment)
  // Handle the comment input change
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  // Function to submit data with comment and close the popup
  const handleSubmitWithComment = () => {
    // Ensure the comment is passed as part of the function
    goNextForm(comment);

    // Clear the comment and close the popup
    // setComment('');
    handleCloseFormModal();
  };

  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Reject Action</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>On confirmation, data will be Reject</p>
            <p>Please Add Reject Remark.</p>

            {/* Add Comment input field */}
            <TextField
              autoFocus
              margin="dense"
              label="Add Remark"
              fullWidth
              variant="outlined"
              value={comment}                 
              onChange={handleCommentChange}  
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleSubmitWithComment();  // Submit with the comment
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              {" "}

              On confirmation ,Records will be appended.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              goNext();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired,
};

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
}

ValidationModal.propTypes = {
  isValidatePopupOpen: PropTypes.bool.isRequired,
  handleCloseValidatePopup: PropTypes.func.isRequired,
  validationAPIFunction: PropTypes.func.isRequired,
}
SubmitDataModal.propTypes = {
  isSubmissionPopupOpen: PropTypes.bool.isRequired,
  handleCloseSubmissionPopup: PropTypes.func.isRequired,
  submitSupplierDVACollection: PropTypes.func.isRequired,
}

export default withAllowedOperationsProvider(VendarData, RESOURCE_TYPE.PROJECT);