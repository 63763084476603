

/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import FormLabel from "@mui/material/FormLabel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
// for print user in consol
import axios from "axios";

import clsx from "clsx";
import { useHistory } from "react-router-dom";
// import Table1 from "../../components/Table/Table";
import Table from "../../components/Table1/Table1";

import styles from "./FinanceMaster.module.css";
import { CustomFormGroup, CustomSelect, ValidatingTextField } from "../../components/FormComponents";
import BackButton from "../../components/BackButton/BackButton";
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex,
} from "../../constants";
import { withAllowedOperationsProvider } from "../../hocs";
import { CustomTab, Button, AuthChecker } from "../../atomicComponents";
import { API } from "../../apis/api";
import { buildErrorMessage } from "../../apis/calls";
import { usePopupManager } from "../../providers/PopupManager/PopupManager";

import StepBar1 from '../../components/StepBar1/StepBar';
// import { error } from "jquery";

const FinanceMaster = () => {


  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  const auth = useSelector((state) => state.auth); // useSelector
  // console.log('auth in Supplier: ', auth);
  const { userRole, userID, userName, vendorcode } = auth
  console.log('userRole: ', userRole, userID, userName);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [checkDisable, setDisable] = useState(true);
  const [Bc, setBC] = useState(null);
  const [projectCode, setProjectCode] = useState(null)
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [asnCount, setAsnCount] = useState(0);
  const [Fy, setFY] = useState(null)
  const [fyArray, setFyArray] = useState([])
  const [quarter, setQuarter] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [latestProject, setLatestProject] = useState("")
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [recentProjectCodeDescription, setRecentProjectCodeDescription] = useState()
  const [dvaSubmissionStatus, setDVASubmissionStatus] = useState({})

  const [loading, setLoader] = useState(false);
  const [loader, setLoading] = useState(true);
  const [vendCode, setvendCode] = useState();
  const [datesData, setDates] = useState({
    endDate: "",
    startDate: "",
  })
  const [projectDescription, setProjectDescription] = useState('')

  // 
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true);
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false);

  // 

  const ROW_HEIGHT = 38;
  const [isValidatePopupOpen, setIsValidatePopupOpen] = useState(false)
  const handleOpenValidatePopup = () => {
    setIsValidatePopupOpen(true)
  }
  const handleCloseValidatePopup = () => {
    setIsValidatePopupOpen(false)
  }

  const [isSubmissionPopupOpen, setIsSubmissionPopupOpen] = useState(false)
  const handleOpenSubmissionPopup = () => {setIsSubmissionPopupOpen(true)}
  const handleCloseSubmissionPopup = () => {setIsSubmissionPopupOpen(false)}

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () => {
    uploadFileData();
  };

  const redirectToApplicationLandingPage = () => {
    history.push("/");
  };

  const setCount = (count) => {
    setAsnCount(count);
  };
  // SubmitStatusUpdate

  const isAdminUser = userRole?.includes('admin')
  const isGDCUser = userRole?.includes('TMLBSL')
  const isPurchaseUser = userRole?.includes('Purchase')
  const isVendorUser = userRole?.includes('Vendor')
  const isPMTUser = userRole?.includes('PMT')
  const isProductLineUser = userRole?.includes('Product-line')
  const isBOEUser = userRole?.includes('BOE')

  const vendorUserID = isVendorUser && userID.toUpperCase()

  useEffect(() => {
    // getData()
    getRecentProjectCode()
    getAllProjectCode()
    // getdate();
    //  Uploadcertification();
  }, [])

  // useEffect(() => {
  //   if (selectedProjectCode) {
  //     getVcForProjectCode(selectedProjectCode)
  //     // getProjectDescription()
  //   }
  // }, [selectedProjectCode])

  useEffect(() => {
    if (selectedProjectCode?.value) {
      setBcArray([]) // Clear plant dropdown options
      setBC(null) // Reset selected plant value
      getVcForProjectCode(selectedProjectCode.value) // Fetch new plant codes
    } else {
      setBcArray([]) // Clear dropdown options
      setBC(null) // Ensure no plant is selected
    }
  }, [selectedProjectCode])
  // useEffect(() => {
  //   if (selectedProjectCode) {
  //     // getVcForProjectCode(selectedProjectCode)
  //     getProjectDescription()
  //   }
  // }, [selectedProjectCode]);

  const apiBody = {
    Project_code: projectCode?.value,
    Supplier_code: Bc?.value,
    // Plant_code: plant?.value,
    Po_Plant: plant?.value,
    Financial_year: Fy?.value,
    Quarter: quarter?.value
  }
  const getVcForProjectCode = async selectedCode => {
    console.log('selectedCode: ', selectedCode)
    // console.log('selectedCode ?.Project_code: ', selectedCode?.Project_code)
    console.log('selectedProjectCode', selectedProjectCode)

    const headerss = {
      'Content-Type': 'application/json'
    }

    const apiBody1 = {
      // project_code: selectedProjectCode?.value || selectedCode?.value,
      Project_code: selectedProjectCode?.value || selectedCode?.value
      // SPOC: preferredUsername
    }
    // console.log('PL body: ', apiBody, preferredUsername)

    try {
      const response = await axios.post(
        // `${baseUrl}GetVCsByProject/`,
        // `${baseUrl}GetVCsByProjectAndSPOCForPL/`,
        `${baseUrl}GetVCsByProject/`,
        // `${baseUrl}GetVCsByProjectExemptedData/`,
        apiBody1,
        { headers: headerss }
      )

      if (response.status >= 200 && response.status < 300) {
        const resArray = response.data?.map(value => ({ value, label: value }))
        console.log('resArray: ', resArray)
        setBcArray(resArray)
      } else {
        console.error(
          `Error fetching VC: ${response.status} - ${response.statusText}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${response.status} - ${response.statusText}`
        })
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error(
          `Error fetching VC: ${error.response.status} - ${error.response.data}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.response.status} - ${error.response.data}`
        })
      } else if (error.request) {
        // Request was made but no response was received
        console.error('Error fetching VC: No response received', error.request)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Failed to fetch data. No response received from the server.'
        })
      } else {
        // Something else happened in setting up the request
        console.error('Error fetching VC:', error.message)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.message}`
        })
      }
    }
  }
  useEffect(() => {

  }, [bcArray])
  console.log('Supplier BC: ', Bc);

 

  

  const getRecentProjectCode = async (projectCodeOptions) => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(`${baseUrl}LatestProjectCodeforProductline/`);
      if (status !== 200) {
        throw new Error("Failed to fetch the latest project code");
      }
      console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code,
      }
      setLatestProject(recentProj)
      setSelectedProjectCode(recentProj) // Automatically select the latest project code
      setProjectCode(recentProj)
      // getVcForProjectCode(recentProj)
      // const recentProjectCodeDescription = 
      // getData(recentProj)
    } catch (error) {
      // console.error("Error fetching recent project codes:", error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!',
      });
    }
  }

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      const { data } = await axios.get(`${baseUrl}AllProjectCodeforProductline/`);
      const allProjectCodes = data.map(({ Project_code }) => Project_code);
      const uniqueProjects = [...new Set(allProjectCodes)];
      const projectCodeOptions = uniqueProjects.map((value) => ({
        value,
        label: value,
      }));

      setProjectCodeArray(projectCodeOptions)
      // const projectDesc = uniqueProjects?.maps((item) => ( item?.Project_code === latestProject))
      const projectDesc = uniqueProjects.map((item) => { 
        console.log('item: ', item); 
        return item?.Project_code === latestProject})
      console.log('projectDesc: ', projectDesc)
      console.log('projectdesc latest: ', latestProject, data)
      // const lastElement = array[array.length - 1];
      const latestProjectCodeDesc = data[data?.length - 1]?.Project_code_Description
      console.log('projectDesc last: ', latestProjectCodeDesc);
      setRecentProjectCodeDescription(latestProjectCodeDesc)
      // await getRecentProjectCode(projectCodeOptions);
      // const recentProjectCodeDescription = 
    } catch (error) {
      console.error("Error fetching project codes:", error);
    }
  }

  const showTable = () => {
    // const { userRole } = auth
    if (
      userRole[0] === 'admin' ||
      userRole[0] === 'TMLBSL' ||
      userRole[0] === 'GDC' ||
      userRole[0] === 'Purchase' ||
      isPMTUser
    ) {
      if (projectCode === null || projectCode.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'Project Code is Mandatory'
        })
      } else {
        // getSupplierDVAstatusForbutton()
        getTableData()
        show(true)
      }
    } else if (userRole[0] === 'Vendor') {
      if (projectCode === null || projectCode.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Project Code is Mandatory'
        })
      } else if (Bc === null || Bc.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Supplier Code is Mandatory'
        })
      } else if (plant === null || plant.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Plant Code is Mandatory'
        })
      } else {
        // getProjectDescription()
        // getSupplierDVAstatusForbutton()
        getTableData()
        show(true)
      }
    } else {
      // getSupplierDVAstatusForbutton()
      getTableData()
      show(true)
    }
  }


  // const getProjectDescription = async () => {
  //   try {
  //     const headerss = { 'Content-Type': 'application/json' }
  //     const body = { Project_code: selectedProjectCode?.value }
  //     const { data } = await axios.post(
  //       `${baseUrl}ProjectDescriptionView/`,
  //       body,
  //       { headers: headerss }
  //     )
  //     // console.log('pro desc: ', data)
  //     setProjectDescription(data?.Project_Description)
  //   } catch (error) {
  //     console.error('Error fetching project description:', error)
  //   }
  // }

  // const SupplierDVAstatusForbutton = async () => {
  //   const body = {
  //     Project_code: projectCode?.value,
  //     Supplier_code: Bc?.value,
  //     // Plant_code: plant?.value
  //     Po_Plant: plant?.value
  //   }
  //   try {
  //     const response = await axios.post(`${baseUrl}SupplierDVAstatusForbutton/`, body, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //     console.log('Response data:', response.data)
  //     if (response?.status === 200 || response?.status === 201) {
  //       setDVASubmissionStatus(response?.data[0])
  //     }
  //   } catch (error) {
  //     let errorMessage = 'An error occurred'

  //     if (error.response) {
  //       switch (error.response.status) {
  //         case 400:
  //           errorMessage = 'Bad Request - Invalid parameters'
  //           break
  //         case 404:
  //           errorMessage = 'Not Found - The requested resource was not found'
  //           break
  //         case 500:
  //           errorMessage = 'Internal Server Error - Please try again later'
  //           break
  //         default:
  //           errorMessage = `Unexpected Error: ${error.response.statusText}`
  //       }
  //     } else if (error.request) {
  //       errorMessage = 'No response received from the server'
  //     } else {
  //       errorMessage = `Request Error: ${error.message}`
  //     }
  //   }
  // }

  // const getTableData = async () => {
  //   const apiBodyPurchase = {
  //     Project_code: projectCode?.value,
  //     VC: Bc?.value
  //   }

  //   try {
  //     // const response = await axios.post(`${baseUrl}Exampt_data_API/`, apiBodyPurchase, {
  //     const response = await axios.post(
  //       `${baseUrl}Finance_API/`,
  //       apiBodyPurchase,
  //       {
  //         headers: { 'Content-Type': 'application/json' }
  //       }
  //     )

  //     console.log('table data response', response.data)

  //     const tableData = response?.data.data
  //     setTableArray(tableData)
  //     setLoading(false)
  //     // if (response?.data?.length() > 0) {
  //     //   show(true)
  //     // } else {
  //     //   show(false)
  //     // }
  //   } catch (error) {
  //     if (error.response) {
  //       const { status } = error.response
  //       if (status === 400) {
  //         show(false)
  //         console.error(
  //           'Bad Request: Check the sent data.',
  //           error.response.data
  //         )
  //       } else if (status === 404) {
  //         show(false)
  //         console.error(
  //           'Not Found: The requested resource could not be found.',
  //           error.response.data
  //         )
  //         showPopup({
  //           type: MESSAGE_TYPE.INFORMATION,
  //           contextText: MESSAGE_TYPE.INFORMATION,
  //           // info: error?.response?.data?.status,
  //           info: `Resource not found!`
  //         })
  //       } else if (status === 500) {
  //         show(false)
  //         console.error(
  //           'Internal Server Error: An error occurred on the server.',
  //           error.response.data
  //         )
  //       } else {
  //         console.error('Error:', error.response.data)
  //       }
  //     } else {
  //       console.error(
  //         'Network Error or the request was made but no response was received:',
  //         error.message
  //       )
  //     }
  //     setLoading(false) // Ensure loading state is turned off even when an error occurs
  //   }
  // }

  const getTableData = async () => {
    const apiBodyPurchase = {
      Project_code: projectCode?.value,
      VC: Bc?.value
    }
  
    try {
      const response = await axios.post(`${baseUrl}Finance_API/`, apiBodyPurchase, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      console.log('table data response', response.data);
      
      const tableData = response.data.data;
      setTableArray(tableData);     
      setLoading(false);
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 400) {
          console.error('Bad Request: Check the sent data.', error.response.data);
        } else if (status === 404) {
          console.error('Not Found: The requested resource could not be found.', error.response.data)
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: error?.response?.data?.error ? error?.response?.data?.error : 'Resource not found!',
          })
        } else if (status === 500) {
          console.error('Internal Server Error: An error occurred on the server.', error.response.data);
        } else {
          console.error('Error:', error.response.data);
        }
      } else {
        console.error('Network Error or the request was made but no response was received:', error.message);
      }
      setLoading(false);  // Ensure loading state is turned off even when an error occurs
    }
  }

  console.log(auth.userRole)

  console.log('latestProject: ', latestProject?.value);
  // const getData = (recentProj) => {
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDvaCollection' : 'SupplierDvaCollectionAdminGDC'
  //   const url = auth.userRole[0] === 'Vendor' ? 'ShowVendorData' : 'SupplierDvaCollectionAdminGDCshow'
  //   const formattedBody = {
  //     // Project_code: latestProject?.value
  //     Project_code: recentProj?.value
  //   }
  //   // fetch(`${baseUrl}SupplierDvaCollection/`, {
  //   fetch(`${baseUrl}LoadVendorcodeandplantwrtprojectcode/`, {
  //     method: "POST",
  //     headers: headerss,
  //     body: JSON.stringify(formattedBody)
  //     // body: formattedBody
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log('responseJson: ', responseJson);
  //     // Extract unique values for different fields
  //       const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_code))].filter(Boolean);
  //       // const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))].filter(Boolean);
  //       const uniquePlants = [...new Set(responseJson.map(item => item.Po_Plant))].filter(Boolean);
  //       const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))].filter(Boolean);
  //       const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))].filter(Boolean);
  //       console.log('Filtered response1: ', uniqueSuppliers)
  //       console.log('Filtered response2: ', uniquePlants)
  //       console.log('Filtered response3: ', uniqueQuarters)
  //       console.log('Filtered response4: ', uniqueYears)

  //       let temp = []
  //       if (bcArray.length === 0) {
  //         console.log('MApping vendor: ', uniqueSuppliers.map(value => ({ value, label: value })))
  //         temp = uniqueSuppliers.map(value => ({ value, label: value }))
  //         setBcArray(uniqueSuppliers.map(value => ({ value, label: value })));
  //       }
  //       console.log('Mapping bcArray:', bcArray, 'temp: ', temp)
  //       // setProjectCodeArray(uniqueProjects.map(value => ({ value, label: value })));
  //       console.log(projectCodeArray)
  //       setPlantArray(uniquePlants.map(value => ({ value, label: value })));
  //       setQuarterArray(uniqueQuarters.map(value => ({ value, label: value })));
  //       setFyArray(uniqueYears.map(value => ({ value, label: value })));

  //       // Extract userRole and userID from auth object
  //       // const { userRole, userID } = auth;
  //       console.log('User Role:', userRole, 'User ID:', userID, 'vendorID: ', vendorUserID);

  //       // Check user role to conditionally set BC state
  //       if (userRole.includes("Vendor-CV") || userRole.includes("Vendor")) {
  //         console.log('bcArray: ', bcArray);
          
  //         const singleVendor = bcArray.find(item => item.value === userID);
  //         const singleVendor1 = temp.find(item => item.value === userID);
  //         console.log('singleVendor: ', singleVendor, singleVendor1);
          
  //         if (singleVendor1) {
  //           setBC(singleVendor1);
  //           setDisable(true); // Disable UI elements
  //         }
  //       } else {
  //         setDisable(false); // Enable UI elements
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //       // Handle error (e.g., show error message)
  //     });
  // }

  const uploadFileData = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute(
      "accept",
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    );
    input.click();
    input.onchange = (e) => {
      setLoader(true)
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      fetch(`${baseUrl}SupplierDataaddition/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status);
          if (status1 == "201") {
            //   document.removeChild(input);
            getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",
            });
          }
        })

        .catch((error) => { });
    };
  };

  // const exportExcel = async (excelData) => {
    // const newExcelData = excelData?.map(
    //   (
    //     {
    //       Project_code,
    //       // Plant_code,
    //       Po_Plant,
    //       Supplier_code,
    //       Financial_year,
    //       Quarter,
    //       Part_no,
    //       Part_description,
    //       Supplier_selling_price,
    //       Vendor_remark,
    //       Value_of_Direct_Import_by_us,
    //       Broad_Description_of_parts_imported_by_us,
    //       Value_of_parts_imported_by_our_suppliers,
    //       Broad_Description_of_parts_imported_by_our_suppliers,
    //       Local_Content,
    //       DVAPercentage,
    //       Foreign_Exchange_Rate,
    //       Currency_Name,
    //       Reference_Date,
    //       // IEC_of_Tier_I_Supplier,
    //       IEC_Code,
    //       Filename,
    //       Generate_certificate
    //     },
    //     id
    //   ) => {
    //     let obj = {
    //       id,
    //       Project_code,
    //       // Plant_code,
    //       Po_Plant,
    //       Supplier_code,
    //       Financial_year,
    //       Quarter,
    //       Part_no,
    //       Part_description,
    //       Supplier_selling_price,
    //       Vendor_remark,
    //       Value_of_Direct_Import_by_us,
    //       Broad_Description_of_parts_imported_by_us,
    //       Value_of_parts_imported_by_our_suppliers,
    //       Broad_Description_of_parts_imported_by_our_suppliers,
    //       Local_Content,
    //       DVAPercentage,
    //       Foreign_Exchange_Rate,
    //       Currency_Name,
    //       Reference_Date,
    //       // IEC_of_Tier_I_Supplier,
    //       IEC_Code,
    //       Filename,
    //       Generate_certificate,
    //     }
    //     return obj
    //   }
    // )
    
  //   const excelDataExceptVendor = excelData?.map(
  //     (
  //       {
  //         Project_code,
  //         vc,
  //         Product_Id,
  //         Product_Description,
  //         Model,
  //         Variant,
  //         Xfactory_cost,
  //         Test1,
  //         Test2,
  //         Average_discount_for_last_12_month,
  //         Value_of_semiconductor_to_be_deducted,
  //         Value_of_REM_to_be_deducted
  //       },
  //       id
  //     ) => {
  //       let obj = {
  //         id,
  //         Project_code,
  //         vc,
  //         Product_Id,
  //         Product_Description,
  //         Model,
  //         Variant,
  //         Xfactory_cost,
  //         import_service: Test1,
  //         import_royalty: Test2,
  //         Average_discount_for_last_12_month,
  //         Value_of_semiconductor_to_be_deducted,
  //         Value_of_REM_to_be_deducted
  //       }
  //       return obj
  //     }
  //   )

  //   var fileTypel =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  //   var fileExt = ".xlsx"
  //   const ws = XLSX.utils.json_to_sheet(excelDataExceptVendor);
  //   // const ws = XLSX.utils.json_to_sheet(isVendorUser ? newExcelData : excelDataExceptVendor)
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  //   const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" })
  //   const data = new Blob([excelBuffer], { type: fileTypel })
  //   FileSaver.saveAs(data, `Finance_Master${fileExt}`);
  //   // FileSaver.saveAs(data, `${projectCode?.value}_${Bc?.value}_${plant?.value}${fileExt}`);
  // }


  const exportExcel = async excelData => {
    const headers = {
      // Project_code: projectCode?.value || '',
      // vc: Bc?.value || '',
      Product_Id: '',
      Product_Description: '',
      Model: '',
      Variant: '',
      Xfactory_cost: '',
      import_service: '',
      import_royalty: '',
      Average_discount_for_last_12_month: '',
      Value_of_semiconductor_to_be_deducted: '',
      Value_of_REM_to_be_deducted: ''
    }
  
    const excelDataFormatted = (
      excelData && excelData.length > 0 ? excelData : [headers]
    ).map(
      (
        {
          // Project_code,
          // vc ,
          Product_Id,
          Product_Description,
          Model,
          Variant,
          Xfactory_cost,
          Test1,
          Test2,
          Average_discount_for_last_12_month,
          // Value_of_semiconductor_to_be_deducted,
          // Value_of_REM_to_be_deducted
        },
        id
      ) => ({
        id,
        // Project_code,
        // vc,
        Product_Id,
        Product_Description,
        Model,
        Variant,
        Xfactory_cost,
        import_service: Test1,
        import_royalty: Test2,
        Average_discount_for_last_12_month,
        // Value_of_semiconductor_to_be_deducted,
        // Value_of_REM_to_be_deducted
      })
    )
  
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExt = '.xlsx'
  
    const ws = XLSX.utils.json_to_sheet(excelDataFormatted)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
  
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, `Finance_Master${fileExt}`)
  }
  

  const baseDefaultColumns = [
    {
      width: 130,
      title: "Project Code",
      field: "Project_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "VC",
      field: "vc",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Product ID",
      field: "Product_Id",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Product Description",
      field: "Product_Description",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Model",
      field: "Model",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 110,
      title: "Variant",
      field: "Variant",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "X-factory Cost",
      field: "Xfactory_cost",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "Import Service",
      field: "Test1",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "Import Royalty",
      field: "Test2",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "Average discount for last 12 month",
      field: "Average_discount_for_last_12_month",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 150,
    //   title: "Value of semiconductor to be deducted",
    //   field: "Value_of_semiconductor_to_be_deducted",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Value of REM to be deducted",
    //   field: "Value_of_REM_to_be_deducted",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
  ]
 
  const validationAPIFunction = async () => {

    const apiBodyPurchase = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      // Plant_code: plant?.value,
      Po_Plant: plant?.value,
      Purchase_submitted_Name: userName,
      Purchase_submitted_Date: formattedCurrentDate
    }
    const apiBodyTMLBSL = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value, 
      // Plant_code: plant?.value,
      Po_Plant: plant?.value,
      TMLBSL_submitted_Name: userName,
      TMLBSL_submitted_Date: formattedCurrentDate
    }
    const apiBodyPMT = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value, 
      // Plant_code: plant?.value,
      Po_Plant: plant?.value,
      PMT_submitted_Name: userName,
      PMT_submitted_Date: formattedCurrentDate
    }

    let url
  
    if (isPurchaseUser) {
      url = 'PurchaseSubmissionstatusForSupplierDVA'
    } else if (isGDCUser) {
      url = 'TMLBSLSubmissionstatusForSupplierDVA'
    } else {
      url = 'PMTSubmissionstatusForSupplierDVA'
    }
  
    const apiFormattedDataJson = JSON.stringify(isGDCUser ? apiBodyTMLBSL : isPurchaseUser ? apiBodyPurchase : apiBodyPMT)
    const headerss = {
      'Content-Type': 'application/json'
    }
  
    try {
      const response = await axios.post(`${baseUrl}${url}/`, apiFormattedDataJson, { headers: headerss })
      console.log('response from axios: ', response)
      if (response.data?.ok || response?.status == '200') {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data submitted successfully!'
        })
      } else {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Data submission failed!'
        })
      }
  
      history.push('/')
    } catch (error) {
      console.error('API error: ', error)
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'An error occurred during data validation!'
      })
    }
    autoSendBackAPI()
  }
  

  const goNextForm = async () => {
    let url = 'PMTSendBackStatusForSupplierDva'

    const sendBackBodyPMT = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      // Plant_code: plant?.value
      Po_Plant: plant?.value
    }

    const apiFormattedDataJson = JSON.stringify(sendBackBodyPMT)
    const headerss = {
      'Content-Type': 'application/json'
    }

    try {
      const response = await axios.post(
        `${baseUrl}${url}/`,
        apiFormattedDataJson,
        { headers: headerss }
      )

      if (
        response.data?.ok ||
        response?.data?.status === 'Records updated successfully' ||
        response?.status == '200'
      ) {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data sent back for Re-Submission'
        })
      } else {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Could not send data for Re-Submission!'
        })
      }

      history.push('/')
    } catch (error) {
      console.error('API error: ', error)
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'An error occurred while sending data for Re-Submission!'
      })
    }
  }

  
  const formattedCurrentDate = new Date().toLocaleDateString('en-GB').split('/').join('/')
  const submitSupplierDVACollection = async () => {
    const newAPIBody = {
      ...apiBody,
      Vendor_submitted_Date: formattedCurrentDate
    }
    const apiFormattedDataJson = JSON.stringify(newAPIBody);
    const headerss = {
      'Content-Type': 'application/json'
    };
  
    try {
      // const response = await axios.post(`${baseUrl}SubmitStatusUpdate/`, apiFormattedDataJson, { headers: headerss });
      const response = await axios.post(`${baseUrl}SupplierSubmissionstatus/`, apiFormattedDataJson, { headers: headerss });
      
      // Handle the response
      console.log("responseJson", response.data);
  
      showPopup({
        type: MESSAGE_TYPE.SUCCESS,
        contextText: MESSAGE_TYPE.SUCCESS,
        info: "Supplier DVA Submitted Successfully!",
      })
      history.push('/')
    } catch (error) {
      console.error('API error:', error);
      
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'An error occurred while submitting Supplier DVA!'
      });
    }
    autoSendBackAPI()
  }

  const autoSendBackAPI = async () => {
    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      // Plant_code: plant?.value
      Po_Plant: plant?.value
    }
    try {
      await axios({
        method: 'POST',
        url: `${baseUrl}AutosendbackSupplierDVAubmissionstatus/`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: apiFormatedData,
      })
    } catch (error) {
      let errorMessage = 'An error occurred';
    
      if (error.response) {
        // Handle specific error codes
        switch (error.response.status) {
          case 400:
            errorMessage = 'Bad Request';
            break;
          case 401:
            errorMessage = 'Unauthorized';
            break;
          case 403:
            errorMessage = 'Forbidden';
            break;
          case 404:
            errorMessage = 'Not Found';
            break;
          case 500:
            errorMessage = 'Internal Server Error';
            break;
          default:
            errorMessage = `Error: ${error.response.statusText}`;
        }
      } else if (error.request) {
        // Handle cases where no response was received
        errorMessage = 'No response received from the server';
      } else {
        // Handle other errors
        errorMessage = `Error: ${error.message}`;
      }
      console.log('Error in autoSendBack', errorMessage)      
    } 
  }
  

  // const redirectToCreateProjectPage = () => history.push("/");
  const renderHTML = () => (
    <div className={styles.formGroup}>

      <Grid
        container
        spacing={2}

        style={{ display: 'flex', 
          // justifyContent: "space-between" 
        }}
        columns={12}
      >
        <Grid
          item
          sm={12}
          md={3}
          lg={3}
          // style={{ marginTop: "5px", marginBottom: '5px' }}
        >
        <div
          style={{
            marginLeft: '5px',
            paddingRight: '',
            // marginTop: '1px',
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '54%' }}>
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Project Code'
                options={projectCodeArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                // value={projectCode}
                value={selectedProjectCode}
                defaultValue={latestProject}
                isMulti={false}
                isMandatory={
                  auth.userRole[0] === 'Vendor' || auth.userRole[0] === 'admin'
                }
                isClearable
                onChange={selectedOption => {
                  // handleSelect(e, "VC");
                  setProjectCode(selectedOption)
                  setSelectedProjectCode(selectedOption)
                }}
              />
            </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={3}
          md={3}
          lg={3}
          // sm={12}
          style={{
            // marginTop: '5px',
            // marginBottom: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* <div className={styles.rowDiv}> */}
          <div className={clsx(styles.lableDiv)} style={{ width: '7%' }}>
            <label className={styles.label}>VC</label>
          </div>
          <div className={styles.formDiv} style={{ width: '78%' }}>
            <CustomSelect
              name='bc'
              options={bcArray}
              // isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={Bc}
              isMulti={false}
              isClearable
              onChange={selectedOption => {
                setBC(selectedOption)
              }}
            />
          </div>
          {/* </div> */}
        </Grid>
       
          <Grid
            item
            md={3}
            lg={3}
            sm={3}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <AuthChecker operation='search'>
                {isAuthorized => (
                  <Button
                    className={clsx(styles.actionButton, styles.primaryActionButton)}
                    variant='primary'
                    onClick={showTable}
                    disabled={!isAuthorized}
                    data-testid='confirm-action'
                  >
                    Show Data
                  </Button>
                )}
              </AuthChecker>
            </div>
          </Grid>
        </Grid>
    </div>
  )

  const uploadDocument = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.xlsx')
    // input.setAttribute('multiple', '')
    input.click()

      input.onchange = async e => {
        const formData = new FormData()
        const { files } = e.target
        formData.append('file', e.target.files[0])
        formData.append("VC", Bc?.value);
        formData.append("Project_code", projectCode?.value);
        
        
        // Array.from(files).forEach((file) => {
        //   formData.append('file', file);
        // });

      // const url =
      //   auth.userRole[0] === 'Vendor'
      //     ? 'UploadSupplierData'
      //     : 'SupplierDvaCollectionAdminGDC'

      // const url = isVendorUser ? 'UploadSupplierData' : isPMTUser ? 'UploadPMTdataforSupplierDVA' : isGDCUser ? 'UploadTMLBSLdata' : 'UploadPurchasedata' 

      try {
        const response = await axios.post(`${baseUrl}UploadFinanceData/`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })

        console.log('Upload Response:', response)

        if (response.status === 200 || response.status === 201) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Document Uploaded Successfully'
          })
          getTableData()
        }
      } catch (error) {
        if (error.response) {
          const { status, data } = error.response
          console.log('Error Response:', data)

          let errorMessage = 'An error occurred during the document upload.'

          if (status === 400) {
            errorMessage = 
              // data?.status
              data?.message
              // data?.status.match(/="(.*?)"/)?.[1] ||
              // 'Local Content does not match the calculated value'
          } else if (status === 500) {
            errorMessage = data?.status || 'Server encountered an issue.'
          } else {
            errorMessage = `Unexpected error: ${
              data?.statusText || error.message
            }`
          }

          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            // info: `Error: ${errorMessage}`
            info: errorMessage
          })
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Error: ${error.message || 'Document upload failed'}`
          })
        }
      }
    }
  }

  const secondaryActions = [
    {
      name: "Download",
      authOperation: "download",
      shouldEnable: (selected) => true,
      actionFn: (selected) => {
        exportExcel(tableArray)
      },
      customClass: "",
    },
    {
      name: "Upload",
      authOperation: "upload",
      shouldEnable: (selected) => true,
      actionFn: (selected) => {
        uploadDocument()
      },
      customClass: "",
    }
  ]

  const handleSecondaryActionOnRole = () => {
    if (auth.userRole.includes('admin')) {
      return secondaryActions;
    }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL') || auth.userRole.includes('Purchase') || auth.userRole.includes('PMT')) {
      return secondaryActions.filter((item) => 
        // item.name === 'Download Documents' || 
        // item.name === 'Download Invoices' || 
        // item.name === 'Download Certificate' || 
        item.name === 'Download' || 
        item.name === 'Upload');
    }
    if (auth.userRole.includes('Vendor')) {
      return secondaryActions.filter((item) => 
        // item.name !== 'Download Documents' && 
        // item.name !== 'Download Invoices' && 
        // item.name !== 'Download Certificate');
        item.name !== 'Download');
    }
    return secondaryActions;
  }

  return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper} style={{ marginTop: '10px' }}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
            // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div className={styles.processHeader} style={{ display: 'flex', height: '3em' }}>
                  <div style={{ width: "100%" }}>
                    <CustomTab title="Finance Master" withOutCount isSelected />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: "86%", padding: "0 2em" }}
          >
            <Table
              columns={[...baseDefaultColumns]}
              defaultColumns={[...baseDefaultColumns]}
              // columns={!auth.userRole.includes('Vendor') ? [...baseDefaultColumnsGDC] : [...baseDefaultColumns]}
              // defaultColumns={!auth.userRole.includes('Vendor') ? [...baseDefaultColumnsGDC] : [...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              // primaryAction={handlePrimaryActionOnRole()}
              primaryAction={[]}
              secondaryActions={handleSecondaryActionOnRole()}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={(e) => { }}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
      <ValidationModal
        isValidatePopupOpen={isValidatePopupOpen}
        handleCloseValidatePopup={handleCloseValidatePopup}
        validationAPIFunction={validationAPIFunction}
      />
      <SubmitDataModal
        isSubmissionPopupOpen={isSubmissionPopupOpen}
        handleCloseSubmissionPopup={handleCloseSubmissionPopup}
        submitSupplierDVACollection={submitSupplierDVACollection}
      />
    </>
  );
};
function ValidationModal({ isValidatePopupOpen, handleCloseValidatePopup, validationAPIFunction }) {
  return (
    <Dialog
      open={isValidatePopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Validation</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be validated.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseValidatePopup}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseValidatePopup()
              validationAPIFunction()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function SubmitDataModal({ isSubmissionPopupOpen, handleCloseSubmissionPopup, submitSupplierDVACollection }) {
  return (
    <Dialog
      open={isSubmissionPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Submission</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be Submitted.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseSubmissionPopup}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseSubmissionPopup()
              submitSupplierDVACollection()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModalF({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Send Back</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be sent back for Re-Submission</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseFormModal}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              {" "}

              On confirmation ,Records will be appended.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              goNext();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired,
};

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
}

ValidationModal.propTypes = {
  isValidatePopupOpen: PropTypes.bool.isRequired,
  handleCloseValidatePopup: PropTypes.func.isRequired,
  validationAPIFunction: PropTypes.func.isRequired,
}
SubmitDataModal.propTypes = {
  isSubmissionPopupOpen: PropTypes.bool.isRequired,
  handleCloseSubmissionPopup: PropTypes.func.isRequired,
  submitSupplierDVACollection: PropTypes.func.isRequired,
}

export default withAllowedOperationsProvider(FinanceMaster, RESOURCE_TYPE.PROJECT);
