/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable func-names */
/* eslint-disable one-var */
/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import Table from '../../components/Table/Table'
import styles from './AFSMIS.module.css'
import { CustomFormGroup, CustomSelect } from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import { MESSAGE_TYPE, RESOURCE_TYPE } from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button } from '../../atomicComponents'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

const AFSReport = () => {

  const history = useHistory()
  const { showPopup } = usePopupManager()
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector

  const [flag, show] = useState(false)
  const [tableArray, setTableArray] = useState([]);
  const [asnCount, setAsnCount] = useState(0);
  const [status, setStatus] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false); 
  const [isPopupOpen1, setIsPopupOpen1] = useState(false); 

  // 02/01/2024
  const [financialYear, setFinancialYear] = useState({
    value: '2023-24',
    label: '2023-24'
  })

  const redirectToApplicationLandingPage = () => {
    history.push('/');
  };

  const setCount = count => {
    setAsnCount(count)
  }

  useEffect(() => {
    // getTableData();
    // getdate();
    getAllTableData()

    // Setting last year while loading the component
    const currentDate = new Date()
    const lastYear = new Date(currentDate)
    lastYear.setFullYear(currentDate.getFullYear() - 1)

    const lastYearValue = lastYear.getFullYear()
    console.log('lastYearValue', lastYearValue)
    // setFinancialYear({value: lastYearValue, label: lastYearValue})
    setFinancialYear({ value: '2023-24', label: '2023-24' })
  }, [])


  const convertDateIntoLocalString = (updated_at) => {
    if (updated_at === null || updated_at == '') {
      return ''
    }
    // Parse the date string in "dd/mm/yyyy" format
    const [day, month, year] = updated_at.split('/');
    const date = new Date(`${year}-${month}-${day}`);

    if (Number.isNaN(date)) {
      return ''; // Return empty string for invalid dates
    }
    // console.log('updated date in convertor: ', updated_at);
    // const date = new Date(updated_at)
    const formattedDate = date.toLocaleDateString('en-GB')
    // console.log('updated formatted: ', formattedDate);
    return formattedDate
  }

  const getAllTableData = () => {
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}MIS_Supplier_data/`,
      {
        method: 'GET',
        headers: headerss,
      }).then((response) => response.json())
      .then((responseJson) => {
        const tableData = responseJson;
        // setTableArray(tableData)
        console.log('tableData in MIS_Supplier_data', tableData)

        /* Column mapping */
        const excelData = responseJson?.map(
          (
            {
              Vendor_Code,
              NAME1,
              // Tier,
              PAN,
              GSTIN,
              IEC_Code,
              Supplier_Status,
              Purchase_Validation_status,
              Financial_year,
              TMLBSL_Validation_Status,
              Purchase_PMT_Validator_Name,
              Purchase_PMT_Validation_Date,
              Purchase_Validation_Email_comment,
              TMLBSL_Validator_Name,
              TMLBSL_Validation_Date,
              TMLBSL_Validation_Email_comment,
              BuyerName,
              Overall_Status,
              updated_at
            },
            id
          ) => {
            let obj = {
              id,
              Vendor_Code,
              // Plant_code,
              NAME1,
              // Tier,
              PAN,
              GSTIN,
              IEC_Code,
              Supplier_Status,
              Purchase_Validation_status,
              Financial_year,
              TMLBSL_Validation_Status,
              Purchase_PMT_Validator_Name,
              Purchase_PMT_Validation_Date: convertDateIntoLocalString(Purchase_PMT_Validation_Date),
              Purchase_Validation_Email_comment,
              TMLBSL_Validator_Name,
              TMLBSL_Validation_Date: convertDateIntoLocalString(TMLBSL_Validation_Date),
              TMLBSL_Validation_Email_comment,
              BuyerName,
              Overall_Status,
              updated_at: convertDateIntoLocalString(updated_at)
              // updated_at
            }
            return obj
          }
        )
        setTableArray(excelData)
        console.log('ExcelData: ', excelData);
      })
      .catch((error) => {

      });
  }

  // const getDataForExcel = (param) => {

  //   let headerss = new Headers();
  //   headerss.append('Content-Type', 'application/json');
  //   fetch(`${baseUrl}MIS_Supplier_data/`,
  //     {
  //       method: 'GET',
  //       headers: headerss,
  //     }).then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log('responseJson', responseJson);
  //       if (!financialYear?.value) {
  //         exportExcel(responseJson)
  //       } else {
  //         console.log('tableArray in response', tableArray);
  //         exportExcel(tableArray)
  //       }
  //     })
  //     .catch((error) => {

  //     });

  // };


  const uploadFileData = () => {
    var input = document.createElement('input');
    input.type = "file";
    input.id = "file";
    input.setAttribute("accept", ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel");
    input.click();
    input.onchange = function (e) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      fetch(`${baseUrl}Upload_Vc_list/`,
   //   fetch(`${baseUrl}Add_Vc_for_PLI/`,
        {
          method: 'POST',
          body: formData
        }).then((response) => {
          let status1 = parseInt(response.status)
          if (status1 =="201") {
         //   document.removeChild(input);
            // getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else{
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",
            });
          }
        })
        .catch((error) => {
        });
    }
  }

  const exportExcel = () => {
    var fileTypel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    var fileExt = ".xlsx"
    console.log('inside Export Excel: ', tableArray)
    const newExcelData = tableArray?.map(
      (
        {
          Vendor_Code,
          NAME1,
          // Tier,
          PAN,
          GSTIN,
          Supplier_Status,
          Purchase_Validation_status,
          Financial_year,
          TMLBSL_Validation_Status,
          Purchase_PMT_Validator_Name,
          Purchase_PMT_Validation_Date,
          TMLBSL_Validator_Name,
          TMLBSL_Validation_Date,
          BuyerName,
          Overall_Status,
          updated_at
        },
        id
      ) => {
        let obj = {
          id,
          Financial_year,
          Vendor_Code,
          Vendor_Name: NAME1,
          PAN,
          GSTIN,
          Supplier_Status,
          Submission_Date: convertDateIntoLocalString(updated_at),
          TMLBSL_Validation_Status,
          TMLBSL_Validation_Date: convertDateIntoLocalString(TMLBSL_Validation_Date),
          TMLBSL_Validator_Name,
          Purchase_Validation_status,
          Purchase_PMT_Validation_Date: convertDateIntoLocalString(Purchase_PMT_Validation_Date),
          Purchase_PMT_Validator_Name,
          BuyerName,
          Overall_Status,
          // updated_at
        }
        return obj
      }
    )
    console.log('inside Export Excel 2: ', newExcelData)

    const ws = XLSX.utils.json_to_sheet(newExcelData)
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }; 
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel });
    FileSaver.saveAs(data, `PLI_AFS_Report_PV${fileExt}`); 
  }

  const baseDefaultColumns = [
    {
      width: 200,
      title: 'Financial Year',
      field: 'Financial_year',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Vendor Code',
      field: 'Vendor_Code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 280,
      title: 'Vendor Name',
      field: 'NAME1',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 170,
      title: 'PAN',
      field: 'PAN',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'GSTIN',
      field: 'GSTIN',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Supplier Status',
      field: 'Supplier_Status',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 200,
      title: 'Supplier Submission Date',
      field: 'updated_at',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: 'Certificate Supplier Status',
    //   field: 'Certificate_Supplier_Status',
    //   enableSearch: true,
    //   enableFilter: true
    // },

    {
      width: 200,
      title: 'TMLBSL Validation Status',
      field: 'TMLBSL_Validation_Status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'TMLBSL Validation Date',
      field: 'TMLBSL_Validation_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'TMLBSL Validator',
      field: 'TMLBSL_Validator_Name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'TMLBSL Comment',
      field: 'TMLBSL_Validation_Email_comment',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: 'TMLBSL Certificate Validation Status',
    //   field: 'TMLBSL_Certificate_Validation_Status',
    //   enableSearch: true,
    //   enableFilter: true
    // },

    {
      width: 200,
      title: 'Purchase/PMT Validation status',
      field: 'Purchase_Validation_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Purchase/PMT Validation Date',
      field: 'Purchase_PMT_Validation_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Purchase/PMT Validator',
      field: 'Purchase_PMT_Validator_Name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Purchase/PMT Comment',
      field: 'Purchase_Validation_Email_comment',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: 'Buyer Name',
    //   field: 'BuyerName',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 200,
      title: 'Overall Status',
      field: 'Overall_Status',
      enableSearch: true,
      enableFilter: true
    }
  ]

  const showTable = () => {
    getDataByYear()
    show(true)
  }

  const handleSelect = (selectedOption, name) => {
    if (name == 'FY') {
      show(false)
      setFinancialYear(selectedOption)
    }
  }

  const getDataByYear = () => {
    const apiFormatedData = {
      Financial_year: financialYear?.value
    }
    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}MisDataByFY/`, {
      method: 'POST',
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson?.error) {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: responseJson?.error
          })
        } else {
          const excelData = responseJson?.map(
            (
              {
                Vendor_Code,
                NAME1,
                PAN,
                GSTIN,
                IEC_Code,
                Supplier_Status,
                Purchase_Validation_status,
                Financial_year,
                TMLBSL_Validation_Status,
                Purchase_PMT_Validator_Name,
                Purchase_PMT_Validation_Date,
                TMLBSL_Validator_Name,
                TMLBSL_Validation_Date,
                BuyerName,
                Overall_Status,
                updated_at
              },
              id
            ) => {
              let obj = {
                id,
                Vendor_Code,
                NAME1,
                PAN,
                GSTIN,
                IEC_Code,
                Supplier_Status,
                Purchase_Validation_status,
                Financial_year,
                TMLBSL_Validation_Status,
                Purchase_PMT_Validator_Name,
                Purchase_PMT_Validation_Date: convertDateIntoLocalString(
                  Purchase_PMT_Validation_Date
                ),
                TMLBSL_Validator_Name,
                TMLBSL_Validation_Date: convertDateIntoLocalString(
                  TMLBSL_Validation_Date
                ),
                BuyerName,
                Overall_Status,
                updated_at: convertDateIntoLocalString(updated_at)
              }
              return obj
            }
          )
          setTableArray(excelData)
        }
      })
      .catch(error => {
        console.log('error in PanManualDataForAllVendors1', error)
      })
  }

  // Custom section for FY dropdown.
  const renderHTML = () => (
    <div
    // className={styles.formGroup}
    >
      <Grid
        container
        style={{
          display: 'flex'
        }}
        columns={12}
      >
        <Grid item md={7} lg={7}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '20%' }}>
              <label className={styles.label}>Financial Year</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='FY'
                options={[
                  { value: '2023-24', label: '2023-24' },
                  { value: '2022-23', label: '2022-23' }
                ]}
                // isDisabled={true}
                className={clsx(styles.select, styles.sel1)}
                value={financialYear}
                isClearable
                onChange={e => {
                  handleSelect(e, 'FY')
                }}
                style={{ width: '40%' }}
              />
              <div
                style={{
                  marginLeft: '20px',
                  marginTop: '1px',
                  justifyContent: 'center',
                  display: 'flex'
                }}
              >
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => {
                    showTable()
                  }}
                >
                  Show Data
                </Button>
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={getAllTableData}
                  style={{ margin: '0 0 0 20px' }}
                >
                  Show All Data
                </Button>

                {/* {loader == false && (
                    <AuthChecker operation='download'>
                      {isAuthorized => (
                        <Button
                          className={clsx(
                            styles.actionButton,
                            styles.primaryActionButton
                          )}
                          variant='primary'
                          //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                          onClick={() => {
                            if (tableArray.length >= 1) {
                              exportExcel(tableArray)
                            }
                          }}
                          disabled={!isAuthorized}
                          data-testid='confirm-action'
                          style={{ marginLeft: '5px' }}
                        >
                          download
                        </Button>
                      )}
                    </AuthChecker>
                  )} */}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )


  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
            />
            <div className={styles.topDiv}>
              <div className={styles.processHeader}>
                <div style={{ width: '100%' }}>
                  <CustomTab title="Annual Financial Statement - Report" withOutCount isSelected />
                </div>
                {/* <StepBar1 enable={3} /> */}
              </div>
            </div>
          </div>
        </div>

        {/* Adding dropdown */}
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup
              // header="Supplier Details --- > (1) Details of Tier-I are mandatory.  (2) '*' Import of services including royalty, as disclosed in form 15CA & 15CB of Income Tax Act/ Rules. "
              body={renderHTML()}
            />
          </div>
        </div>
        {/* <Grid
          container
          spacing={1}
          style={{
            display: 'flex',
            justifyContent: 'left',
            // marginTop: '5px',
            marginBottom: '1px'
          }}
          columns={12}
        >
          <Grid item md={11} lg={11}>
            <div className={styles.rowDiv}>
              <div className={clsx(styles.lableDiv)} style={{ width: '20%' }}>
                <label className={styles.label}>Financial Year</label>
              </div>
              <CustomSelect
                name='FY'
                options={[
                  // { value: 'default', label: '2022', isDisabled: true },
                  // { value: '2023', label: '2023'},
                  { value: '2022', label: '2022' },
                  { value: '2021', label: '2021' },
                  { value: '2020', label: '2020' },
                  // { value: '2024', label: '2024'},
                  // { value: '2025', label: '2025'},
                  // { value: '2026', label: '2026'},
                  // { value: '2027', label: '2027'},
                  // { value: '2028', label: '2028'},
                  // { value: '2029', label: '2029'},
                  // { value: '2030', label: '2030'}
                ]}
                // isDisabled={checkDisable}
                // className={clsx(styles.select, styles.sel1)}
                // value={!financialYear ? lastYearValue : financialYear}
                isMulti={false}
                // isClearable
                // onChange={e => {
                //   handleSelect(e, 'FY')
                // }}
                // defaultValue='2023'
                selectedOption='2023'
              />
            </div>
          </Grid>
        </Grid> */}

        <div
          className={styles.tableView}
          style={{ height: '86%', padding: '0 2em' }}
        >


          <Table
            columns={[...baseDefaultColumns]}
            defaultColumns={[...baseDefaultColumns]}
            rowHeight={38}
            rows={tableArray}
            secondaryActions={[
              {
                name: 'Download',
                authOperation: 'download',
                shouldEnable: selected => true,
                // actionFn: selected => { getDataForExcel(); },
                actionFn: selected => { exportExcel(); },
                customClass: ""
              },
            ]}
            setAsnCount={setCount}
            isDataLoading={false}
            actionButtons
            onRowSelect={(e) => { }}
            removeRowSelection
          />
        </div>

      </div>
      {/* <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
      <ResetModal1
        isPopupOpen1={isPopupOpen1}
        handleClose1={handleClose1}
        goNext1={goNext1}
      /> */}
    </>)
}
// function ResetModal({ isPopupOpen, handleClose, goNext }) {
//   return (
//     <Dialog
//       open={isPopupOpen}
//       className={styles.popContainer}
//       classes={{
//         paper: styles.popupBox,
//       }}
//       data-testid="reset-popup"
//     >
//       <>
//         <DialogTitle>
//           <span className={styles.title}>
//             <WarningIcon style={{ height: '18px', width: '18px' }} />
//             <span style={{marginLeft:"8px"}} className={styles.txt}>Confirm file Upload</span>
//           </span>
//         </DialogTitle>
//         <DialogContent className={styles.content}>
//           <div>
//             {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
//             <p>On confirmation ,Records will be appended.</p>
//             <p>Are you sure ?</p>

//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             className={clsx(
//               styles.actionButton,
//               styles.transparentButton
//             )}
//             onClick={handleClose}
//           >Cancel</Button>
//           <Button
//             className={clsx(
//               styles.actionButton,
//               styles.primaryActionButton,
//             )}
//             variant="primary"
//             onClick={(e) => {
//               e.preventDefault();
//               handleClose()
//               goNext()
//             }}
//           >
//             Yes
//           </Button>
//         </DialogActions>
//       </>
//     </Dialog>
//   )
// }
// ResetModal.propTypes = {
//   isPopupOpen: PropTypes.bool.isRequired,
//   handleClose: PropTypes.func.isRequired,
//   goNext: PropTypes.func.isRequired
// }
// function ResetModal1({ isPopupOpen1, handleClose1, goNext1 }) {
//   return (
//     <Dialog
//       open={isPopupOpen1}
//       className={styles.popContainer}
//       classes={{
//         paper: styles.popupBox,
//       }}
//       data-testid="reset-popup"
//     >
//       <>
//         <DialogTitle>
//           <span className={styles.title}>
//             <WarningIcon style={{ height: '18px', width: '18px' }} />
//             <span style={{marginLeft:"8px"}} className={styles.txt}>Selected data will be deleted</span>
//           </span>
//         </DialogTitle>
//         <DialogContent className={styles.content}>
//           <div>
//             <p> On confirmation, Selected data will be deleted.</p>
//             <p>Are you sure ?</p>

//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             className={clsx(
//               styles.actionButton,
//               styles.transparentButton
//             )}
//             onClick={handleClose1}
//           >Cancel</Button>
//           <Button
//             className={clsx(
//               styles.actionButton,
//               styles.primaryActionButton,
//             )}
//             variant="primary"
//             onClick={(e) => {
//               e.preventDefault();
//               goNext1()
//               handleClose1()
//             }}
//           >
//             Yes
//           </Button>
//         </DialogActions>
//       </>
//     </Dialog>
//   )
// }
// ResetModal1.propTypes = {
//   isPopupOpen1: PropTypes.bool.isRequired,
//   handleClose1: PropTypes.func.isRequired,
//   goNext1: PropTypes.func.isRequired
// }
export default withAllowedOperationsProvider(AFSReport, RESOURCE_TYPE.PROJECT)
