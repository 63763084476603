/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable object-shorthand */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  //   Grid,
  FormGroup,
  IconButton
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import FormLabel from '@mui/material/FormLabel'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Bar, Pie, Doughnut } from 'react-chartjs-2'
// import {
//   Chart as ChartJS,
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   PointElement,
//   LinearScale,
//   BarElement,
//   LineElement
// } from 'chart.js'
import { Card, CardContent, Typography, Grid } from '@mui/material'
import Chart from 'chart.js/auto'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import styles from './DashBoard.module.css'
import {
  CustomFormGroup,
  CustomSelect,
  ValidatingTextField
} from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
import { API } from '../../apis/api'
import { buildErrorMessage } from '../../apis/calls'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

import StepBar1 from '../../components/StepBar1/StepBar'
import { GridLoadingSpinner } from '../../components'
// import { error } from "jquery";

const AfsDashboard = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  const auth = useSelector(state => state.auth) // useSelector
  // console.log('auth in Supplier: ', auth);
  const { userRole, userID, userName, vendorcode } = auth
  console.log('userRole: ', userRole, userID, userName)
  const [dynamicColumns, setDynamicColumns] = useState([])
  const [dynamicColumns1, setDynamicColumns1] = useState([])
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  const [checkDisable, setDisable] = useState(true)
  const [Bc, setBC] = useState(null)
  const [projectCode, setProjectCode] = useState(null)
  const [bcArray, setBcArray] = useState([])
  const [plantArray, setPlantArray] = useState([])
  const [tableArray, setTableArray] = useState([])
  const [excelArray, setExcelArray] = useState([])
  const [asnCount, setAsnCount] = useState(0)
  const [Fy, setFY] = useState(null)
  const [fyArray, setFyArray] = useState([
    {
      value: '2023-24',
      label: '2023-24'
    }
  ])
  const [financialYear, setFinancialYear] = useState({
    value: '2023-24',
    label: '2023-24'
  })
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false) // New state for button enable/disable
  const [quarter, setQuarter] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [latestProject, setLatestProject] = useState('')
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [recentProjectCodeDescription, setRecentProjectCodeDescription] =
    useState()
  const [dvaSubmissionStatus, setDVASubmissionStatus] = useState({})

  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  const [vendCode, setvendCode] = useState()
  const [datesData, setDates] = useState({
    endDate: '',
    startDate: ''
  })
  const [projectDescription, setProjectDescription] = useState('')

  const [show1, setShow1] = useState(false)
  const [activeTable, setActiveTable] = useState(null)
  const [isDataView, setIsDataView] = useState(true)

  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)
  // const [doughnutData, setDoughnutData] = useState([]);
  const [doughnutData, setDoughnutData] = useState({
    partValue: [], // For Part Value Doughnut Charts
    partCount: [] // For Part Count Doughnut Charts
  })
  const [combinedData, setCombinedData] = useState({
    labels: [], // Initialize empty
    datasets: [
      {
        type: 'bar',
        label: 'Part Value',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      },
      {
        type: 'line',
        label: 'Cumulative Percentage',
        data: [],
        backgroundColor: 'rgba(255, 206, 86, 0.6)',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1
      }
    ]
  })
  const [pieData, setPieData] = useState({
    labels: [], // Default to an empty array
    datasets: [
      {
        label: 'Supplier Count',
        data: [], // Default to an empty array
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1
      }
    ]
  })
  //

  const ROW_HEIGHT = 38
  const [isValidatePopupOpen, setIsValidatePopupOpen] = useState(false)
  const handleOpenValidatePopup = () => {
    setIsValidatePopupOpen(true)
  }
  const handleCloseValidatePopup = () => {
    setIsValidatePopupOpen(false)
  }

  const [isSubmissionPopupOpen, setIsSubmissionPopupOpen] = useState(false)
  const handleOpenSubmissionPopup = () => {
    setIsSubmissionPopupOpen(true)
  }
  const handleCloseSubmissionPopup = () => {
    setIsSubmissionPopupOpen(false)
  }

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  // const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  // const goNext = () => {
  //   uploadFileData()
  // }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }
  // SubmitStatusUpdate

  const isAdminUser = userRole?.includes('admin')
  const isGDCUser = userRole?.includes('TMLBSL')
  const isPurchaseUser = userRole?.includes('Purchase')
  const isVendorUser = userRole?.includes('Vendor')
  const isPMTUser = userRole?.includes('PMT')
  const isProductLineUser = userRole?.includes('Product-line')
  const isBOEUser = userRole?.includes('BOE')

  const vendorUserID = isVendorUser && userID.toUpperCase()

  // useEffect(() => {
  //   // getData()
  //   getRecentProjectCode()
  //   getAllProjectCode()
  //   // getdate();
  //   //  Uploadcertification();
  // }, [])

  // ChartJS.register(
  //   ArcElement,
  //   Tooltip,
  //   Legend,
  //   CategoryScale,
  //   LinearScale,
  //   BarElement,
  //   PointElement,
  //   LineElement
  // )

  const apiBody = {
    Project_code: projectCode?.value,
    Supplier_code: Bc?.value,
    // Plant_code: plant?.value,
    Po_Plant: plant?.value,
    Financial_year: Fy?.value,
    Quarter: quarter?.value
  }

  useEffect(() => {}, [bcArray])
  console.log('Supplier BC: ', Bc)

  //  const showTable = () => {
  //     // getTableData()
  //     console.log('financialYear', financialYear);
  //     getDataByYear()
  //     getDataByYear1()
  //     show(true)
  //   }
  //   const showTable1 = () => {
  //     // getTableData()
  //     console.log('financialYear', financialYear);
  //     getDataByYear1()
  //     show(true)
  //   }

  const showTable = () => {
    setTableArray([])
    console.log('financialYear', financialYear)
    getDataByYear()
    setActiveTable('data')
    setIsDataView(true)
    setShow1(true)
  }

  const showTable1 = () => {
    // Clear existing table data to avoid conflicts
    setTableArray([])

    // Fetch new data and update the table
    // getDataByYearBar();
    getDataByYearBarPie()
    fetchDoughnutChartData()
    setActiveTable('list')
    setIsDataView(false)
    setShow1(true)
  }
  const showTable2 = () => {
    // Clear existing table data to avoid conflicts
    setTableArray([])

    // Fetch new data and update the table
    getDataByYear1()
    setActiveTable('table')
    setIsDataView(false)
    setShow1(true)
  }
  const handleSelect = (selectedOption, name) => {
    console.log('selectedOption', selectedOption)

    if (name == 'FY') {
      show(false)
      setFinancialYear(selectedOption)
      console.log('inside FY select')
      // getDataByYear()
    }
  }
  const getProjectDescription = async () => {
    try {
      const headerss = { 'Content-Type': 'application/json' }
      const body = { Project_code: selectedProjectCode?.value }
      const { data } = await axios.post(
        `${baseUrl}ProjectDescriptionView/`,
        body,
        { headers: headerss }
      )
      // console.log('pro desc: ', data)
      setProjectDescription(data?.Project_Description)
    } catch (error) {
      console.error('Error fetching project description:', error)
    }
  }

  const getData = recentProj => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDvaCollection' : 'SupplierDvaCollectionAdminGDC'
    const url =
      auth.userRole[0] === 'Vendor'
        ? 'ShowVendorData'
        : 'SupplierDvaCollectionAdminGDCshow'
    const formattedBody = {
      // Project_code: latestProject?.value
      Project_code: recentProj?.value
    }
    // fetch(`${baseUrl}SupplierDvaCollection/`, {
    fetch(`${baseUrl}LoadVendorcodeandplantwrtprojectcode/`, {
      method: 'POST',
      headers: headerss,
      body: JSON.stringify(formattedBody)
      // body: formattedBody
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log('responseJson: ', responseJson)
        // Extract unique values for different fields
        const uniqueSuppliers = [
          ...new Set(responseJson.map(item => item.Supplier_code))
        ].filter(Boolean)
        // const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))].filter(Boolean);
        const uniquePlants = [
          ...new Set(responseJson.map(item => item.Po_Plant))
        ].filter(Boolean)
        const uniqueQuarters = [
          ...new Set(responseJson.map(item => item.Quarter))
        ].filter(Boolean)
        const uniqueYears = [
          ...new Set(responseJson.map(item => item.Financial_year))
        ].filter(Boolean)

        // Extract unique values for different fields
        // const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_Code))];
        // const uniqueProjects = [...new Set(responseJson.map(item => item.Project_code))];
        // const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))];
        // const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))];
        // const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))];
        console.log('Filtered response1: ', uniqueSuppliers)
        console.log('Filtered response2: ', uniquePlants)
        console.log('Filtered response3: ', uniqueQuarters)
        console.log('Filtered response4: ', uniqueYears)

        let temp = []
        if (bcArray.length === 0) {
          console.log(
            'MApping vendor: ',
            uniqueSuppliers.map(value => ({ value, label: value }))
          )
          temp = uniqueSuppliers.map(value => ({ value, label: value }))
          // setBcArray(uniqueSuppliers.map(value => ({ value, label: value })))
        }
        console.log('Mapping bcArray:', bcArray, 'temp: ', temp)
        // setProjectCodeArray(uniqueProjects.map(value => ({ value, label: value })));
        console.log(projectCodeArray)
        setPlantArray(uniquePlants.map(value => ({ value, label: value })))
        setQuarterArray(uniqueQuarters.map(value => ({ value, label: value })))
        setFyArray(uniqueYears.map(value => ({ value, label: value })))

        // Extract userRole and userID from auth object
        // const { userRole, userID } = auth;
        console.log(
          'User Role:',
          userRole,
          'User ID:',
          userID,
          'vendorID: ',
          vendorUserID
        )

        // Check user role to conditionally set BC state
        if (userRole.includes('Vendor-CV') || userRole.includes('Vendor')) {
          console.log('bcArray: ', bcArray)

          const singleVendor = bcArray.find(item => item.value === userID)
          const singleVendor1 = temp.find(item => item.value === userID)
          console.log('singleVendor: ', singleVendor, singleVendor1)

          if (singleVendor1) {
            setBC(singleVendor1)
            setDisable(true) // Disable UI elements
          }
        } else {
          setDisable(false) // Enable UI elements
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error)
        // Handle error (e.g., show error message)
      })
  }

  const getVcForProjectCode = async selectedCode => {
    console.log('selectedCode: ', selectedCode)
    // console.log('selectedCode ?.Project_code: ', selectedCode?.Project_code)
    console.log('selectedProjectCode', selectedProjectCode)

    const headerss = {
      'Content-Type': 'application/json'
    }

    const apiBody1 = {
      // project_code: selectedProjectCode?.value || selectedCode?.value,
      Project_code: selectedProjectCode?.value || selectedCode?.value
      // SPOC: preferredUsername
    }
    // console.log('PL body: ', apiBody1, preferredUsername)

    try {
      const response = await axios.post(
        // `${baseUrl}GetVCsByProject/`,
        // `${baseUrl}GetVCsByProjectAndSPOCForPL/`,
        `${baseUrl}GetVCsByProject/`,
        apiBody1,
        { headers: headerss }
      )

      if (response.status >= 200 && response.status < 300) {
        const resArray = response.data?.map(value => ({ value, label: value }))
        console.log('resArray: ', resArray)
        setBcArray(resArray)
      } else {
        console.error(
          `Error fetching VC: ${response.status} - ${response.statusText}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${response.status} - ${response.statusText}`
        })
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error(
          `Error fetching VC: ${error.response.status} - ${error.response.data}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.response.status} - ${error.response.data}`
        })
      } else if (error.request) {
        // Request was made but no response was received
        console.error('Error fetching VC: No response received', error.request)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Failed to fetch data. No response received from the server.'
        })
      } else {
        // Something else happened in setting up the request
        console.error('Error fetching VC:', error.message)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.message}`
        })
      }
    }
  }

  const getAllProjectCode = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}AllProjectCodeforProductline/`
      )
      const allProjectCodes = data.map(({ Project_code }) => Project_code)
      const uniqueProjects = [...new Set(allProjectCodes)]
      const projectCodeOptions = uniqueProjects.map(value => ({
        value,
        label: value
      }))

      setProjectCodeArray(projectCodeOptions)
      await getRecentProjectCode(projectCodeOptions)
    } catch (error) {
      console.error('Error fetching project codes:', error)
    }
  }

  const getRecentProjectCode = async projectCodeOptions => {
    try {
      const { data, status } = await axios.get(
        `${baseUrl}LatestProjectCodeforProductline/`
      )
      if (status !== 200) {
        throw new Error('Failed to fetch the latest project code')
      }
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code
      }
      setLatestProject(recentProj)
      setSelectedProjectCode(recentProj) // latest project code
    } catch (error) {
      console.error('Error fetching recent project codes:', error)
    }
  }

  useEffect(() => {
    getAllProjectCode()
  }, [])

  useEffect(() => {
    if (latestProject) {
      setSelectedProjectCode(latestProject)
    }
  }, [latestProject])

  // Check if the selected project code is the latest project code
  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(
        selectedProjectCode.value === latestProject.value
      )
    }
  }, [selectedProjectCode, latestProject])

  // Fetch Plant Codes based on selected Project Code
  const fetchPlantCodes = async projectCode1 => {
    try {
      const { data } = await axios.post(
        `${baseUrl}LoadPoPlantwrtprojectcode/`,
        {
          Project_code: projectCode1 // Sending the project code in the body
        }
      )
      // const plantCodeOptions = data.map(({ Po_Plant }) => ({
      //   value: Po_Plant,
      //   label: Po_Plant
      // }))
      const plantCodeOptions = data
        .filter(({ Po_Plant }) => Po_Plant?.trim()) // Filter out blank values
        .map(({ Po_Plant }) => ({
          value: Po_Plant,
          label: Po_Plant
        }))
      setPlantArray(plantCodeOptions)
    } catch (error) {
      console.error('Error fetching plant codes:', error)
    }
  }

  // useEffect(() => {
  //   if (selectedProjectCode && selectedProjectCode.value) {
  //     // Fetch Plant Codes only if a Project Code is selected
  //     fetchPlantCodes(selectedProjectCode.value)
  //   } else {
  //     setPlantArray([]) // Clear Plant Code dropdown when no Project Code is selected
  //   }
  // }, [selectedProjectCode])
  useEffect(() => {
    if (selectedProjectCode?.value) {
      setPlantArray([]) // Clear plant dropdown options
      setPlant(null) // Reset selected plant value
      fetchPlantCodes(selectedProjectCode.value) // Fetch new plant codes
    } else {
      setPlantArray([]) // Clear dropdown options
      setPlant(null) // Ensure no plant is selected
    }
  }, [selectedProjectCode])

  //   const handleProjectCodeChange = (selectedOption) => {
  //     setSelectedProjectCode(selectedOption);
  //   };
  // Handle Project Code selection
  const handleProjectCodeChange = selectedOption => {
    setSelectedProjectCode(selectedOption)
    if (selectedOption) {
      fetchPlantCodes(selectedOption.value)
    } else {
      setPlantArray([]) // Clear m dropdown if no Project Code is selected
      setPlant(null)
    }
  }

  const exportExcel = (dataArray, isViewData) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'

    // Map data based on the active view
    const newExcelData = dataArray?.map(row => {
      if (isViewData) {
        const {
          CTL,
          Part_count,
          Part_value,
          Supplier_count,
          SA_Part_count,
          SA_Part_value,
          SA_DVA_value,
          SA_Supplier_count,
          VA_Part_count,
          VA_Part_value,
          VA_DVA_value,
          VA_Supplier_count
        } = row
        return {
          CTL: CTL,
          Part_count: Part_count,
          Part_value: Part_value,
          Supplier_count: Supplier_count,
          SA_Part_count: SA_Part_count,
          SA_Part_value: SA_Part_value,
          SA_DVA_value: SA_DVA_value,
          SA_Supplier_count: SA_Supplier_count,
          VA_Part_count: VA_Part_count,
          VA_Part_value: VA_Part_value,
          VA_DVA_value: VA_DVA_value,
          VA_Supplier_count: VA_Supplier_count
        }
      }
      // Export for "Show List"
      const { CTL, supplier_count, sa_cert_requried, sa_crt_pending } = row
      return {
        CTL: CTL,
        supplier_count: supplier_count,
        sa_cert_requried: sa_cert_requried,
        sa_crt_recivied: sa_cert_requried,
        sa_crt_pending: sa_crt_pending
      }
    })

    // Create worksheet and workbook
    const ws = XLSX.utils.json_to_sheet(newExcelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }

    // Write workbook to buffer
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

    // Create Blob and trigger download
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(
      data,
      `DVADashboard_${new Date().toISOString().slice(0, 10)}${fileExtension}`
    )
  }

  const renderHTML = () => (
    <div className={styles.formGroup} style={{ display: 'flex' }}>
      {loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )}

      <Grid
        container
        spacing={1}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        columns={5}
      >
        {/* Project Code */}
        <Grid
          item
          md={3}
          lg={3}
          xs={3}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className={styles.rowDiv}>
            <div
              className={clsx(styles.lableDiv)}
              style={{ width: '50%', display: 'flex', alignItems: 'center' }}
            >
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              {/* <CustomSelect
                name="Project Code"
                options={projectCodeArray}
                className={clsx(styles.select, styles.sel1)}
                value={selectedProjectCode}
                isMulti={false}
                defaultValue={latestProject}
                isClearable
                onChange={(selectedOption) => {
                  setSelectedProjectCode(selectedOption);
                }}
              /> */}
              <CustomSelect
                name='Project Code'
                options={projectCodeArray}
                className={clsx(styles.select, styles.sel1)}
                value={selectedProjectCode}
                isMulti={false}
                defaultValue={latestProject}
                isClearable
                onChange={handleProjectCodeChange}
              />
            </div>
          </div>
        </Grid>

        {/* Plant Code */}
        <Grid
          item
          md={2}
          lg={2}
          xs={2}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className={styles.rowDiv}>
            <div
              className={clsx(styles.lableDiv)}
              style={{ width: '32%', display: 'flex', alignItems: 'center' }}
            >
              <label className={styles.label}>Plant</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Plant'
                options={plantArray}
                className={clsx(styles.select, styles.sel1)}
                value={plant}
                isMulti={false}
                isClearable
                isMandatory={auth.userRole[0] === 'Vendor'}
                onChange={selectedOption => {
                  setPlant(selectedOption)
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      {/* Buttons */}
      <Grid
        container
        spacing={1}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end'
        }}
        columns={7}
      >
        <Grid
          item
          md={6}
          lg={6}
          xs={6}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <AuthChecker operation='search'>
            {isAuthorized => (
              <Button
                className={clsx(
                  styles.actionButton,
                  styles.primaryActionButton
                )}
                variant='primary'
                onClick={showTable}
                disabled={!isAuthorized}
              >
                DVA Summary
              </Button>
            )}
          </AuthChecker>

          <AuthChecker operation='search'>
            {isAuthorized => (
              <Button
                className={clsx(
                  styles.actionButton,
                  styles.primaryActionButton
                )}
                variant='primary'
                style={{ marginLeft: '10px' }}
                onClick={showTable1}
                disabled={!isAuthorized}
              >
                Graphical Representation
              </Button>
            )}
          </AuthChecker>

          <AuthChecker operation='search'>
            {isAuthorized => (
              <Button
                className={clsx(
                  styles.actionButton,
                  styles.primaryActionButton
                )}
                variant='primary'
                style={{ marginLeft: '10px' }}
                onClick={showTable2}
                disabled={!isAuthorized}
              >
                SA Table
              </Button>
            )}
          </AuthChecker>

          <AuthChecker operation='search'>
            {isAuthorized => (
              <Button
                className={clsx(
                  styles.actionButton,
                  styles.primaryActionButton
                )}
                variant='primary'
                onClick={() => exportExcel(tableArray, isDataView)}
                disabled={!isAuthorized}
                style={{ marginLeft: '10px' }}
              >
                Download
              </Button>
            )}
          </AuthChecker>
        </Grid>
      </Grid>
    </div>
  )

  const getDataByYear = () => {
    // Prepare the request payload
    const apiFormattedData = {
      Project_code: selectedProjectCode?.value,
      Po_plant: plant?.value // Include the selected plant code
      // Uncomment and include the financial year if necessary
      // financial_year: financialYear?.value,
    }
    const apiFormattedDataJson = JSON.stringify(apiFormattedData)

    // Set headers
    let headers = new Headers()
    headers.append('Content-Type', 'application/json')

    // Make the API request
    fetch(`${baseUrl}Dashboard_PV/`, {
      method: 'POST',
      headers: headers,
      body: apiFormattedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log('responseJson:', responseJson)

        if (responseJson?.error) {
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: responseJson?.error
          })
        } else {
          const responseData = responseJson?.data || [] // Extract the data array from the response
          console.log('Response Data:', responseData)

          // Map data to the expected table format
          const mappedTableData = responseData.map((item, id) => {
            // Normalize keys based on the response
            const {
              CTL,
              Part_count,
              Part_value,
              Supplier_count,
              SA_Part_count,
              SA_Part_value,
              SA_DVA_value,
              SA_Supplier_count,
              VA_Part_count,
              VA_Part_value,
              VA_DVA_value,
              VA_Supplier_count
            } = item

            return {
              id,
              CTL:
                CTL || (id === responseData.length - 1 ? 'Grand Total' : ' '), // Label "Grand Total" for the last item
              Part_count: Part_count ?? 0,
              Part_value: Part_value ?? 0,
              Supplier_count: Supplier_count ?? 0,
              SA_Part_count: SA_Part_count ?? 0,
              SA_Part_value: SA_Part_value ?? 0,
              SA_DVA_value: SA_DVA_value ?? 0,
              SA_Supplier_count: SA_Supplier_count ?? 0,
              VA_Part_count: VA_Part_count ?? 0,
              VA_Part_value: VA_Part_value ?? 0,
              VA_DVA_value: VA_DVA_value ?? 0,
              VA_Supplier_count: VA_Supplier_count ?? 0
            }
          })

          // Update the table's data source
          setTableArray(mappedTableData)
          console.log('Mapped Table Data:', mappedTableData)
        }
      })
      .catch(error => {
        console.error('Error in getDataByYear:', error)
      })
  }

  const getDataByYear1 = () => {
    const apiFormatedData = {
      Project_code: selectedProjectCode.value,
      Po_plant: plant?.value // Include the selected plant code
    }

    const apiFormatedDataJson = JSON.stringify(apiFormatedData)

    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')

    fetch(`${baseUrl}Dashboard_PV_SA/`, {
      method: 'POST',
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log('responseJson:', responseJson)

        if (responseJson?.error) {
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: responseJson?.error
          })
        } else {
          const tableData = responseJson?.data || []

          // Transforming the response data for the table
          const formattedData = tableData.map(
            (
              {
                CTL,
                supplier_count,
                sa_cert_requried,
                sa_crt_recivied,
                sa_crt_pending
              },
              index
            ) => ({
              id: index + 1,
              CTL: CTL || '-',
              supplier_count: supplier_count || '0',
              sa_cert_requried: sa_cert_requried || '0',
              sa_crt_recivied: sa_crt_recivied || '0',
              sa_crt_pending: sa_crt_pending || '0'
            })
          )

          setTableArray(formattedData)
        }
      })
      .catch(error => {
        console.error('Error in getDataByYear:', error)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: 'Error in API call',
          info: error.message
        })
      })
  }

  // const getDataByYearBar = () => {
  //   const apiFormattedData = {
  //     Project_code: selectedProjectCode?.value,
  //     Po_plant: plant?.value,
  //   };
  //   const apiFormattedDataJson = JSON.stringify(apiFormattedData);

  //   let headers = new Headers();
  //   headers.append("Content-Type", "application/json");

  //   fetch(`${baseUrl}Dashboard_PV/`, {
  //     method: "POST",
  //     headers: headers,
  //     body: apiFormattedDataJson,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       if (responseJson?.error) {
  //         showPopup({
  //           type: MESSAGE_TYPE.INFORMATION,
  //           contextText: MESSAGE_TYPE.INFORMATION,
  //           info: responseJson?.error,
  //         });
  //       } else {
  //         const responseData = responseJson?.data || [];

  //         // Filter out "Grand Total" entries
  //         const filteredData = responseData.filter(
  //           (item) => item.CTL !== "Grand Total"
  //         );

  //         // Log to verify filtering
  //         console.log("Filtered Data (No Grand Total):", filteredData);

  //         // Extract CTL and Part_value
  //         const labels = filteredData.map((item) => item.CTL );
  //         const partValues = filteredData.map((item) => item.Part_value || 0);

  //         // Ensure the chart does not include "Grand Total"
  //         if (labels.includes("Grand Total")) {
  //           console.error(
  //             "Grand Total is still present in labels. Check the filtering logic."
  //           );
  //         }

  //         // Update Bar chart data
  //         const updatedCombinedData = {
  //           labels, // Labels from the filtered data
  //           datasets: [
  //             {
  //               type: "bar",
  //               label: "Part Value",
  //               data: partValues, // Part_value from the filtered data
  //               backgroundColor: "rgba(75, 192, 192, 0.6)",
  //               borderColor: "rgba(75, 192, 192, 1)",
  //               borderWidth: 1,
  //             },
  //             {
  //               type: "line",
  //               label: "Cumulative Percentage",
  //               data: calculateCumulativePercentage(partValues), // Dynamically calculate
  //               backgroundColor: "rgba(255, 206, 86, 0.6)",
  //               borderColor: "rgba(255, 206, 86, 1)",
  //               borderWidth: 1,
  //             },
  //           ],
  //         };

  //         // Update state with filtered data
  //         setCombinedData(updatedCombinedData);
  //         console.log("Updated Combined Data:", updatedCombinedData);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error in getDataByYear:", error);
  //     });
  // };

  const getDataByYearBarPie = () => {
    const apiFormattedData = {
      Project_code: selectedProjectCode?.value,
      Po_plant: plant?.value
    }
    const apiFormattedDataJson = JSON.stringify(apiFormattedData)

    let headers = new Headers()
    headers.append('Content-Type', 'application/json')

    fetch(`${baseUrl}Dashboard_PV/`, {
      method: 'POST',
      headers: headers,
      body: apiFormattedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson?.error) {
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: responseJson?.error
          })
        } else {
          let responseData = responseJson?.data || []

          // Filter out "Grand Total" entries
          let filteredData = responseData.filter(
            item => item.CTL !== 'Grand Total'
          )

          // Sort data in ascending order by Part_value for Bar Chart
          filteredData = filteredData.sort(
            (a, b) => (a.Part_value || 0) - (b.Part_value || 0)
          )

          // Extract data for bar chart
          const labels = filteredData.map((item, index) => item.CTL)
          const partValues = filteredData.map(item => item.Part_value || 0)

          // Extract data for pie chart (sorted by Supplier_count)
          let sortedPieData = [...filteredData].sort(
            (a, b) => (a.Supplier_count || 0) - (b.Supplier_count || 0)
          )
          const pieLabels = sortedPieData.map(item => item.CTL)
          const supplierCounts = sortedPieData.map(
            item => item.Supplier_count || 0
          )

          // Update Bar chart data
          const updatedCombinedData = {
            labels, // Labels from the filtered data (sorted)
            datasets: [
              {
                type: 'bar',
                label: 'Part Value',
                data: partValues, // Sorted Part_value
                backgroundColor: 'rgb(115, 162, 206)',
                borderColor: 'rgb(115, 162, 206)',
                borderWidth: 1
              },
              {
                type: 'line',
                label: 'Cumulative Values',
                data: calculateCumulativePercentage(partValues), // Sorted dynamically
                backgroundColor: 'rgb(54, 96, 146)',
                borderColor: 'rgb(54, 96, 146)',
                borderWidth: 2,
                pointBorderWidth: 2, // Makes data points more visible
                pointRadius: 2 // Increases the size of points on the line
              }
            ]
          }

          setCombinedData(updatedCombinedData)

          // Update Pie Chart Data
          const updatedPieData = {
            labels: pieLabels, // CTL from sorted pie data
            datasets: [
              {
                label: 'Supplier Count',
                data: supplierCounts, // Sorted Supplier_count
                backgroundColor: [
                  'rgb(54, 96, 146)',
                  'rgb(115, 162, 206)',
                  'rgb(227, 108, 47)',
                  'rgb(160, 160, 160)',
                  'rgb(90, 172, 68)',
                  'rgb(55, 100, 200)',
                  'rgb(249, 183, 53)'
                ],
                borderColor: ['rgba(255, 255, 255, 1)'],
                borderWidth: 1
              }
            ]
          }

          setPieData(updatedPieData) // Update pie chart state
          console.log('Updated Pie Data:', updatedPieData)
        }
      })
      .catch(error => {
        console.error('Error in getDataByYear:', error)
      })
  }

  // const fetchDoughnutChartData = () => {
  //   const apiFormattedData = {
  //     Project_code: selectedProjectCode?.value,
  //     Po_plant: plant?.value
  //   }

  //   const apiFormattedDataJson = JSON.stringify(apiFormattedData)

  //   let headers = new Headers()
  //   headers.append('Content-Type', 'application/json')

  //   fetch(`${baseUrl}Dashboard_PV/`, {
  //     method: 'POST',
  //     headers: headers,
  //     body: apiFormattedDataJson
  //   })
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       if (responseJson?.error) {
  //         showPopup({
  //           type: MESSAGE_TYPE.INFORMATION,
  //           contextText: MESSAGE_TYPE.INFORMATION,
  //           info: responseJson?.error
  //         })
  //         return
  //       }

  //       const responseData = responseJson?.data || []

  //       // Ensure "Grand Total" is excluded completely
  //       const filteredData = responseData.filter(
  //         item => item.CTL !== 'Grand Total'
  //       )

  //       // Dynamically generate data for Doughnut charts (Part Value)
  //       const partValueChartsData = filteredData.map((item, index) => ({
  //         labels: ['Part Value', 'SA Part Value', 'VA Part Value'],
  //         datasets: [
  //           {
  //             label: `Part Value ${index + 1}`,
  //             data: [
  //               Number(item.Part_value) || 0,
  //               Number(item.SA_Part_value) || 0,
  //               Number(item.VA_Part_value) || 0
  //             ],
  //             backgroundColor: [
  //               'rgba(255, 99, 132, 0.6)',
  //               'rgba(255, 206, 86, 0.6)',
  //               'rgba(75, 192, 192, 0.6)'
  //             ],
  //             borderColor: [
  //               'rgba(255, 99, 132, 1)',
  //               'rgba(255, 206, 86, 1)',
  //               'rgba(75, 192, 192, 1)'
  //             ],
  //             borderWidth: 1
  //           }
  //         ]
  //       }))

  //       // Dynamically generate data for Doughnut charts (Part Count)
  //       const partCountChartsData = filteredData.map((item, index) => ({
  //         labels: ['Part Count', 'SA Part Count', 'VA Part Count'],
  //         datasets: [
  //           {
  //             label: `Part Count ${index + 1}`,
  //             data: [
  //               Number(item.Part_count) || 0,
  //               Number(item.SA_Part_count) || 0,
  //               Number(item.VA_Part_count) || 0
  //             ],
  //             backgroundColor: [
  //               'rgba(54, 162, 235, 0.6)',
  //               'rgba(153, 102, 255, 0.6)',
  //               'rgba(255, 159, 64, 0.6)'
  //             ],
  //             borderColor: [
  //               'rgba(54, 162, 235, 1)',
  //               'rgba(153, 102, 255, 1)',
  //               'rgba(255, 159, 64, 1)'
  //             ],
  //             borderWidth: 1
  //           }
  //         ]
  //       }))

  //       // Set filtered and transformed data for doughnut charts
  //       setDoughnutData({
  //         partValue: partValueChartsData,
  //         partCount: partCountChartsData
  //       })
  //     })
  //     .catch(error => {
  //       console.error('Error fetching doughnut chart data:', error)
  //     })
  // }

  const fetchDoughnutChartData = () => {
    const apiFormattedData = {
      Project_code: selectedProjectCode?.value,
      Po_plant: plant?.value
    }

    const apiFormattedDataJson = JSON.stringify(apiFormattedData)

    let headers = new Headers()
    headers.append('Content-Type', 'application/json')

    fetch(`${baseUrl}Dashboard_PV/`, {
      method: 'POST',
      headers: headers,
      body: apiFormattedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson?.error) {
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: responseJson?.error
          })
          return
        }

        const responseData = responseJson?.data || []

        // Ensure "Grand Total" is excluded completely
        const filteredData = responseData.filter(
          item => item.CTL !== 'Grand Total'
        )

        // Dynamically generate data for Doughnut charts (Part Value)
        const partValueChartsData = filteredData.map(item => ({
          labels: ['SA Part Value', 'UV Part Value', 'NS Part Value'],
          datasets: [
            {
              label: `Part Value - ${item.CTL}`, // Store CTL for reference
              data: [
                Number(item.SA_Part_value) || 0,
                Number(item.VA_Part_value) || 0,
                Number(item.oth_prt_val) || 0
              ],
              backgroundColor: [
                'rgba(144, 238, 144, 2)',
                'rgba(255, 255, 0, 1)',
                'rgba(255, 0, 0, 0.8)'
              ],
              borderColor: ['rgba(255, 255, 255, 1)'],
              borderWidth: 1
            }
          ],
          CTL: item.CTL // Store CTL name for easy access
        }))

        // Dynamically generate data for Doughnut charts (Part Count)
        const partCountChartsData = filteredData.map(item => ({
          labels: ['SA Part Count', 'UV Part Count', 'NS Part Count'],
          datasets: [
            {
              label: `Part Count - ${item.CTL}`,
              data: [
                Number(item.SA_Part_count) || 0,
                Number(item.VA_Part_count) || 0,
                Number(item.oth_prt) || 0
              ],
              backgroundColor: [
                // 'rgba(100, 200, 100, 1)',

                'rgba(144, 238, 144, 2)',
                'rgba(255, 255, 0, 1)',
                'rgba(255, 0, 0, 0.8)'
              ],
              borderColor: ['rgba(255, 255, 255, 1)'],
              borderWidth: 1
            }
          ],
          CTL: item.CTL // Store CTL name for easy access
        }))

        // Set filtered and transformed data for doughnut charts
        setDoughnutData({
          partValue: partValueChartsData,
          partCount: partCountChartsData
        })
      })
      .catch(error => {
        console.error('Error fetching doughnut chart data:', error)
      })
  }

  // Helper function to calculate cumulative percentages
  // const calculateCumulativePercentage = (data) => {
  //   const total = data.reduce((sum, value) => sum + value, 0);
  //   let cumulativeSum = 0;
  //   return data.map((value) => {
  //     cumulativeSum += value;
  //     return ((cumulativeSum / total) * 100).toFixed(2); // Percentage
  //   });
  // };
  const calculateCumulativePercentage = data => {
    let cumulativeSum = 0
    return data.map(value => {
      cumulativeSum += value
      return cumulativeSum // Return cumulative sum
    })
  }

  const combinedOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top'
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.datasetIndex === 1) {
              return `${tooltipItem.raw}` // Display percentage for Pareto
            }
            return `₹${tooltipItem.raw}`
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'CTL'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Part Value (₹)'
        },
        beginAtZero: true
      }
      //   y1: {
      //     position: 'right',
      //     title: {
      //       display: true,
      //       text: 'Cumulative Percentage',
      //     },
      //     ticks: {
      //       callback: function (value) {
      //         return `${value}%`;
      //       },
      //     },
      //     grid: {
      //       drawOnChartArea: false, // Ensure secondary grid lines don't overlap
      //     },
      // },
    }
  }

  const renderOther = () => (
    <div style={{ margin: '0px', borderRadius: '5px', overflow: 'hidden' }}>
      {/* Scrollable Section with Sticky Header */}
      <div
        style={{
          padding: '10px 0',
          backgroundColor: '#f9f9f9',
          maxHeight: '425px',
          overflowY: 'auto',
          overflowX: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: '#2e75b5 #f5f5f5'
        }}
      >
        <style>
          {`
            div::-webkit-scrollbar {
              height: 8px;
            }
            div::-webkit-scrollbar-track {
              background: #f5f5f5;
              border-radius: 10px;
            }
            div::-webkit-scrollbar-thumb {
              background: #2e75b5;
              border-radius: 10px;
            }
            div::-webkit-scrollbar-thumb:hover {
              background: #1d5a92;
            }
          `}
        </style>

        {/* Header */}
        <div
          style={{
            display: 'flex',
            minWidth: '2260px',
            backgroundColor: '#f9f9f9',
            borderBottom: '2px solid #2e75b5',
            fontWeight: 'bold',
            // position: 'sticky',
            top: 0,
            zIndex: 1
          }}
        >
          <span style={{ minWidth: '100px', textAlign: 'center' }}>
            {' '}
            <label className={styles.label}>Sr No</label>
          </span>
          <span style={{ minWidth: '100px', textAlign: 'center' }}>
            {' '}
            <label className={styles.label}>CTL</label>
          </span>
          <span style={{ minWidth: '200px', textAlign: 'center' }}>
            <label className={styles.label}>Part Count</label>
          </span>
          <span style={{ minWidth: '150px', textAlign: 'center' }}>
            <label className={styles.label}>Part Value</label>
          </span>
          <span style={{ minWidth: '150px', textAlign: 'center' }}>
            <label className={styles.label}>Supplier Count</label>
          </span>
          <span style={{ minWidth: '200px', textAlign: 'center' }}>
            <label className={styles.label}>SA Part Count</label>
          </span>
          <span style={{ minWidth: '200px', textAlign: 'center' }}>
            <label className={styles.label}>SA Part Value</label>
          </span>
          <span style={{ minWidth: '180px', textAlign: 'center' }}>
            <label className={styles.label}>SA DVA Value</label>
          </span>
          <span style={{ minWidth: '230px', textAlign: 'center' }}>
            <label className={styles.label}>SA Supplier Count</label>
          </span>
          <span style={{ minWidth: '150px', textAlign: 'center' }}>
            <label className={styles.label}>UV Part Count</label>
          </span>
          <span style={{ minWidth: '150px', textAlign: 'center' }}>
            <label className={styles.label}>UV Part Value</label>
          </span>
          <span style={{ minWidth: '230px', textAlign: 'center' }}>
            <label className={styles.label}>UV DVA Value</label>
          </span>
          <span style={{ minWidth: '230px', textAlign: 'center' }}>
            <label className={styles.label}>UV Supplier Count</label>
          </span>
        </div>

        {/* Rows */}
        {tableArray
          ?.filter(row => row.CTL !== 'Grand Total')
          .map((row, index) => (
            <div
              key={row.CTL || row.uniqueKey} // Ensure each object has a unique key
              style={{
                display: 'flex',
                minWidth: '2260px',
                borderBottom: '1px solid #ccc',
                padding: '5px 0'
              }}
            >
              <span style={{ minWidth: '100px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>{index + 1}</label>
              </span>
              <span style={{ minWidth: '100px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>{row.CTL || '-'}</label>
              </span>
              <span style={{ minWidth: '200px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>{row.Part_count || '-'}</label>
              </span>
              <span style={{ minWidth: '150px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>
                  {row.Part_value.toFixed(2) || '-'}
                </label>
              </span>
              <span style={{ minWidth: '150px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>
                  {row.Supplier_count || '-'}
                </label>
              </span>
              <span style={{ minWidth: '200px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>
                  {row.SA_Part_count || '-'}
                </label>
              </span>
              <span style={{ minWidth: '200px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>
                  {row.SA_Part_value.toFixed(2) || '-'}
                </label>
              </span>
              <span style={{ minWidth: '180px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>
                  {row.SA_DVA_value.toFixed(2) || '-'}
                </label>
              </span>
              <span style={{ minWidth: '230px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>
                  {row.SA_Supplier_count || '-'}
                </label>
              </span>
              <span style={{ minWidth: '150px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>
                  {row.VA_Part_count || '-'}
                </label>
              </span>
              <span style={{ minWidth: '150px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>
                  {row.VA_Part_value.toFixed(2) || '-'}
                </label>
              </span>
              <span style={{ minWidth: '230px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>
                  {row.VA_DVA_value.toFixed(2) || '-'}
                </label>
              </span>
              <span style={{ minWidth: '230px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label1}>
                  {row.VA_Supplier_count || '-'}
                </label>
              </span>
            </div>
          ))}

        {/* Grand Total */}
        {tableArray
          ?.filter(row => row.CTL === 'Grand Total')
          .map(row => (
            <div
              key={row.CTL || row.uniqueKey}
              style={{
                display: 'flex',
                minWidth: '2260px',
                fontWeight: 'bold',
                color: '#4E4E4E',
                borderTop: '2px solid #2e75b5',
                backgroundColor: '#f1f1f1',
                padding: '10px 0'
              }}
            >
              <span style={{ minWidth: '100px', textAlign: 'center' }}> </span>
              <span style={{ minWidth: '100px', textAlign: 'center' }}>
                <label className={styles.label}>
                  {row.CTL || 'Grand Total'}
                </label>
              </span>
              <span style={{ minWidth: '200px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label}>{row.Part_count || '-'}</label>
              </span>
              <span style={{ minWidth: '150px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label}>
                  {row.Part_value.toFixed(2) || '-'}
                </label>
              </span>
              <span style={{ minWidth: '150px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label}>
                  {row.Supplier_count1 || '-'}
                </label>
              </span>
              <span style={{ minWidth: '200px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label}>
                  {row.SA_Part_count || '-'}
                </label>
              </span>
              <span style={{ minWidth: '200px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label}>
                  {row.SA_Part_value.toFixed(2) || '-'}
                </label>
              </span>
              <span style={{ minWidth: '180px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label}>
                  {row.SA_DVA_value.toFixed(2) || '-'}
                </label>
              </span>
              <span style={{ minWidth: '230px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label}>
                  {row.SA_Supplier_count1 || '-'}
                </label>
              </span>
              <span style={{ minWidth: '150px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label}>
                  {row.VA_Part_count || '-'}
                </label>
              </span>
              <span style={{ minWidth: '150px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label}>
                  {row.VA_Part_value.toFixed(2) || '-'}
                </label>
              </span>
              <span style={{ minWidth: '230px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label}>
                  {row.VA_DVA_value.toFixed(2) || '-'}
                </label>
              </span>
              <span style={{ minWidth: '230px', textAlign: 'center' }}>
                {' '}
                <label className={styles.label}>
                  {row.VA_Supplier_count1 || '-'}
                </label>
              </span>
            </div>
          ))}
      </div>
    </div>
  )
  const renderSA = () => (
    <div
      style={{
        margin: '0px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        overflow: 'hidden'
      }}
    >
      {/* Header */}
      <div
        style={{
          borderBottom: '2px solid #2e75b5',
          padding: '10px 0',
          backgroundColor: '#f9f9f9'
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>Sr No</label>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>CTL</label>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>Supplier Count</label>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>SA cert Required</label>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>SA crt Received</label>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>SA crt Pending</label>
          </Grid>
        </Grid>
      </div>

      {/* Scrollable Middle Section */}
      <div
        style={{
          maxHeight: '370px',
          overflowY: 'auto',
          overflowX: 'hidden',
          scrollbarWidth: 'thin',
          scrollbarColor: '#2e75b5 #f5f5f5'
        }}
      >
        <style>
          {`
          div::-webkit-scrollbar {
            width: 8px;
          }
          div::-webkit-scrollbar-track {
            background: #f5f5f5;
            border-radius: 10px;
          }
          div::-webkit-scrollbar-thumb {
            background: #2e75b5;
            border-radius: 10px;
          }
          div::-webkit-scrollbar-thumb:hover {
            background: #1d5a92;
          }
        `}
        </style>
        {tableArray
          ?.filter(row => row.CTL !== 'Grand Total')
          .map((row, index) => (
            <Grid
              key={`${row.CTL}-${row.supplier_count}-${row.sa_crt_recivied}`}
              container
              spacing={2}
              style={{
                borderBottom: '1px solid #ccc',
                padding: '7px 0'
              }}
            >
              <Grid item xs={1} style={{ textAlign: 'center' }}>
                <label className={styles.label1}>{index + 1}</label>
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <label className={styles.label1}>{row.CTL}</label>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                <label className={styles.label1}>{row.supplier_count}</label>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                <label className={styles.label1}>{row.sa_cert_requried}</label>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                <label className={styles.label1}>{row.sa_crt_recivied}</label>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                <label className={styles.label1}>{row.sa_crt_pending}</label>
              </Grid>
            </Grid>
          ))}
      </div>

      {/* Footer */}
      <div
        style={{
          borderTop: '2px solid #2e75b5',
          padding: '10px 0',
          backgroundColor: '#f9f9f9'
        }}
      >
        {tableArray
          ?.filter(row => row.CTL === 'Grand Total')
          .map((row, index) => (
            <Grid
              key={`${row.CTL}-${row.supplier_count}-${row.sa_crt_recivied}`}
              container
              spacing={2}
              style={{
                fontWeight: 'bold',
                color: '#4E4E4E'
              }}
            >
              <Grid item xs={1} style={{ textAlign: 'center' }} />
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                {row.CTL}
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                {row.supplier_count}
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                {row.sa_cert_requried}
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                {row.sa_crt_recivied}
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                {row.sa_crt_pending}
              </Grid>
            </Grid>
          ))}
      </div>
    </div>
  )

  const renderList = () => (
    <div
      style={{
        padding: '10px 0',
        backgroundColor: '#f9f9f9',
        maxHeight: '430px',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#2e75b5 #f5f5f5'
      }}
    >
      <Grid container spacing={3}>
        {/* First Row: Bar Chart and Pie Chart */}
        <Grid item xs={8}>
          <Card>
            <CardContent
              style={{
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <Typography variant='h6' align='center' gutterBottom>
                Part Value
              </Typography>
              <div style={{ flex: 1 }}>
                <Bar data={combinedData} options={combinedOptions} />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent
              style={{
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <Typography variant='h6' align='center' gutterBottom>
                Supplier Count
              </Typography>
              <div style={{ flex: 1 }}>
                <Pie data={pieData} />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Second Row: Part Count Doughnut Charts */}
      <div
        style={{
          display: 'flex',
          gap: '16px',
          overflowX: 'auto',
          padding: '16px 0'
        }}
      >
        {doughnutData.partCount.map((data, index) => (
          <div
            key={`part-count-${data.labels.join('-')}-${
              data.datasets[0]?.label
            }`}
            style={{
              flex: '0 0 auto',
              width: '340px' // Fixed size for uniformity
            }}
          >
            <Card>
              <CardContent>
                <Typography variant='h6' align='center' gutterBottom>
                  <span style={{ fontSize: '0.8em' }}>
                    Part Status - {data.CTL}
                  </span>
                </Typography>
                <Doughnut data={data} />
              </CardContent>
            </Card>
          </div>
        ))}
      </div>

      {/* Third Row: Part Value Doughnut Charts */}
      <div
        style={{
          display: 'flex',
          gap: '16px',
          overflowX: 'auto',
          padding: '16px 0'
        }}
      >
        {doughnutData.partValue.map((data, index) => (
          <div
            key={`part-value-${data.labels.join('-')}-${
              data.datasets[0]?.label
            }`}
            style={{
              flex: '0 0 auto',
              width: '340px' // Fixed size for uniformity
            }}
          >
            <Card>
              <CardContent>
                <Typography variant='h6' align='center' gutterBottom>
                  <span style={{ fontSize: '0.8em' }}>
                    {' '}
                    Part Value - {data.CTL}
                  </span>
                </Typography>
                <Doughnut data={data} />
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%', marginLeft: '5px' }}>
                    <CustomTab title='DVA Dashboard' withOutCount isSelected />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {/* {flag && (
          <div
            className={styles.tableView}
            style={
              {
                // height: '86%',
                // padding: '0 2em'
              }
            }
          >
            <div className={styles.formContainer1}>
              <CustomFormGroup body={renderOther()} />
            </div>
            <div className={styles.formContainer1}>
              <CustomFormGroup body={renderOther1()} />
            </div>
          </div>
        )} */}
        {show1 && (
          <div className={styles.tableView}>
            <div className={styles.formContainer1}>
              {activeTable === 'data' && (
                <CustomFormGroup body={renderOther()} />
              )}
              {activeTable === 'list' && (
                <CustomFormGroup body={renderList()} />
              )}
              {activeTable === 'table' && <CustomFormGroup body={renderSA()} />}
            </div>
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
    </>
  )
}

export default withAllowedOperationsProvider(
  AfsDashboard,
  RESOURCE_TYPE.PROJECT
)
