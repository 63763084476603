/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
// import FormLabel from '@mui/material/FormLabel'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
// import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
// import $ from 'jquery';
import { useHistory } from 'react-router-dom'
import Table from '../../components/Table/Table'
// import Table from '../../components/Table1/Table1'
import styles from './SupplierUnderPLIVendor.module.css'
import {
  CustomFormGroup,
  CustomSelect,
  ValidatingTextField
} from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  // DATE_FORMAT,
  // API_RESOURCE_URLS,
  // DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  // MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE
  // USER_OPERATIONS,
  // Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
// import { API } from '../../apis/api'
// import { buildErrorMessage } from '../../apis/calls'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

// import StepBar1 from '../../components/StepBar1/StepBar'
import { GridLoadingSpinner } from '../../components'

const VendarData = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  const baseUrl = useSelector(state => state.counter.baseUrl)
  const auth = useSelector(state => state.auth)
  const { userRole, userID, userName } = auth
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  const [checkDisable, setDisable] = useState(true)
  const [Bc, setBC] = useState(null)
  const [projectCode, setProjectCode] = useState(null)
  const [bcArray, setBcArray] = useState([])
  const [tableArray, setTableArray] = useState([])
  // const [Fy,setFY] = useState(null)
  const [fyArray, setFyArray] = useState([])
  // const [quarter,setQuarter] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false)
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  const [vendCode, setvendCode] = useState()
  const [projectDescription, setProjectDescription] = useState('')

  const [pliSubmissionStatus, setPliSubmissionStatus] = useState({})

  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [latestProject, setLatestProject] = useState('')
  const [recentProjectCodeDescription, setRecentProjectCodeDescription] =
    useState()

  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  const ROW_HEIGHT = 38

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  const goNext = () => {
    uploadFileData()
  }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  useEffect(() => {
    getRecentProjectCode()
    getAllProjectCode()
  }, [])

  useEffect(() => {
    if (latestProject) {
      setSelectedProjectCode(latestProject)
    }
  }, [latestProject])

  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(
        selectedProjectCode.value === latestProject.value
      )
    }
  }, [selectedProjectCode, latestProject])

  useEffect(() => {
    if (selectedProjectCode) {
      getProjectDescription()
    }
  }, [selectedProjectCode])

  const getProjectDescription = async () => {
    try {
      const headerss = { 'Content-Type': 'application/json' }
      const body = { Project_code: selectedProjectCode?.value }
      const { data } = await axios.post(
        `${baseUrl}ProjectDescriptionView/`,
        body,
        { headers: headerss }
      )
      setProjectDescription(data?.Project_Description)
    } catch (error) {
      console.error('Error fetching project description:', error)
    }
  }

  const newDate = new Date()
  const formattedDate = newDate.toLocaleDateString('en-GB')

  const GetSupplierCodeAPI = async latestProject1 => {
    const headerss = {
      'Content-Type': 'application/json'
    }
    const apiFormattedDataJson = JSON.stringify({
      Project_code:
        latestProject1?.value == null ||
        latestProject1?.value == '' ||
        latestProject1?.value == undefined
          ? selectedProjectCode?.value
          : latestProject1?.value
    })
    const { data } = await axios.post(
      `${baseUrl}GetSupplierCode/`,
      apiFormattedDataJson,
      { headers: headerss }
    )
    console.log('supplier res: ', data)

    const dropdownOptions = data?.Supplier_codes.filter(code => code) // Filter out empty strings and null values
      .map(code => ({ value: code, label: code }))
    console.log('Supplier res option: ', dropdownOptions)
    setBcArray(dropdownOptions)

    if (userRole.includes('Vendor-CV') || userRole.includes('Vendor')) {
      const singleVendor = dropdownOptions.find(item => item.value === userID)
      if (singleVendor) {
        setBC(singleVendor)
        setDisable(true)
      }
    } else {
      setDisable(false)
    }
  }

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      const { data } = await axios.get(
        `${baseUrl}AllProjectCodeforProductline/`
      )
      const allProjectCodes = data.map(({ Project_code }) => Project_code)
      const uniqueProjects = [...new Set(allProjectCodes)]
      const projectCodeOptions = uniqueProjects.map(value => ({
        value,
        label: value
      }))

      setProjectCodeArray(projectCodeOptions)
      // const projectDesc = uniqueProjects?.maps((item) => ( item?.Project_code === latestProject))
      const projectDesc = uniqueProjects.map(item => {
        console.log('item: ', item)
        return item?.Project_code === latestProject
      })
      console.log('projectDesc: ', projectDesc)
      console.log('projectdesc latest: ', latestProject, data)
      // const lastElement = array[array.length - 1];
      const latestProjectCodeDesc =
        data[data?.length - 1]?.Project_code_Description
      console.log('projectDesc last: ', latestProjectCodeDesc)
      setRecentProjectCodeDescription(latestProjectCodeDesc)
    } catch (error) {
      console.error('Error fetching project codes:', error)
    }
  }

  const getRecentProjectCode = async projectCodeOptions => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(
        `${baseUrl}LatestProjectCodeforProductline/`
      )
      if (status !== 200) {
        throw new Error('Failed to fetch the latest project code')
      }
      console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code
      }
      setLatestProject(recentProj)
      setProjectCode(recentProj)
      setSelectedProjectCode(recentProj)
      GetSupplierCodeAPI(recentProj)
    } catch (error) {
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!'
      })
    }
  }

  // const handleDropdownChange = (selectedOption, name) => {
  //   if (name === 'Project_code') {
  //     setSelectedProjectCode(selectedOption)
  //   }
  // }
  const handleDropdownChange = (selectedOption, name) => {
    if (name === 'Project_Code') {
      // Update the selected project code
      setSelectedProjectCode(selectedOption);
      // setBC(selectedOption);
    }
    else if (name === 'Supplier') {
      // Update the selected supplier
      setBC(selectedOption);
    }
  };
  const showTable = () => {
    if (projectCode === null || projectCode.value === '') {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project Code is Mandatory'
      })
    } else if (Bc === null || Bc?.value === '' || Bc === undefined) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Supplier Code is Mandatory'
      })
    } else {
      getTableData()
      getSupplierunderPLIstatusForbutton()
      show(true)
    }
  }

  const getSupplierunderPLIstatusForbutton = async () => {
    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value
    }
    try {
      const response = await axios.post(
        `${baseUrl}SupplierunderPLIstatusForbutton/`,
        apiFormatedData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      console.log('Response Status: ', response)
      if (response.status === 200 || response.status === 201) {
        const tableData = response?.data
        console.log('Response Status: ', tableData[0])
        setPliSubmissionStatus(tableData[0])
      } else if (response.status === 404) {
        // showPopup({
        //   type: MESSAGE_TYPE.FAILURE,
        //   contextText: MESSAGE_TYPE.FAILURE,
        //   info: response?.data
        // })
      } else {
        console.error('Error fetching table data:', response.statusText)
        // showPopup({
        //   type: MESSAGE_TYPE.FAILURE,
        //   contextText: MESSAGE_TYPE.FAILURE,
        //   info: `Error: ${response.statusText}`
        // })
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Error 404: No records found')
        // showPopup({
        //   type: MESSAGE_TYPE.FAILURE,
        //   contextText: MESSAGE_TYPE.FAILURE,
        //   info: error.response.data.error // or just `info: 'No records found'`
        // })
      } else {
        console.error('Error fetching table data:', error)
        // showPopup({
        //   type: MESSAGE_TYPE.FAILURE,
        //   contextText: MESSAGE_TYPE.FAILURE,
        //   info: `Error: ${error.message}`
        // })
      }
    }
  }

  const getTableData = async () => {
    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value
    }
    setLoader(true)
    try {
      const response = await axios.post(
        // `${baseUrl}VendorUnderPLIIncentiveData/`,
        `${baseUrl}VendorUnderPLIIncentiveDataNew/`,
        apiFormatedData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if (response.status === 200 || response.status === 201) {
        const tableData = response?.data?.data
        setTableArray(tableData)
        setLoading(false)
        setLoader(false)
      } else {
        console.error('Error fetching table data:', response.statusText)
        setLoader(false)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Error: ${response.statusText}`
        })
      }
    } catch (error) {
      setLoader(false)
      console.error('Error fetching table data:', error)
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: `Error: ${error.message}`
      })
    }
  }

  const getAllData = () => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}AllSupplierDeclarationData/`, {
      method: 'GET',
      headers: headerss
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson === null || responseJson.length === 0) {
          // setTableArray(responseJson)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'No data available'
          })
        } else {
          setTableArray(responseJson)
          show(true)
          setLoading(false)
        }
      })
      .catch(error => {
        console.log('error', error)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Error while fetching data.'
        })
      })
  }

  const uploadFileData = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute(
      'accept',
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    )
    input.click()
    input.onchange = e => {
      setLoader(true)
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      fetch(`${baseUrl}SupplierDataadditionPV/`, {
        method: 'POST',
        body: formData
      })
        .then(response => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status)
          if (status1 == '201') {
            //   document.removeChild(input);
            getTableData()
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded sucessfully'
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded failed'
            })
          }
        })

        .catch(error => {})
    }
  }

  const uploadDocument = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.xlsx')
    input.click()

    input.onchange = async e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      setLoader(true)
      try {
        const response = await axios.post(
          // `${baseUrl}uploadunderPLIdata/`,
          `${baseUrl}uploadunderPLIdata1/`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        console.log('Upload res: ', response)

        if (response.status === 200 || response.status === 201) {
          setLoader(false)
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Document Uploaded Successfully!'
          })
          getTableData()
        } else {
          setLoader(false)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Document upload failed!`
          })
        }
      } catch (error) {
        setLoader(false)
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Error response:', error.response)
          let errorMessage = 'Document upload failed!'

          if (error.response.status === 400) {
            // errorMessage = 'Bad Request: The server could not understand the request due to invalid syntax.';
            errorMessage = error?.response?.data?.errors
          } else if (error.response.status === 401) {
            errorMessage =
              'Unauthorized: Access is denied due to invalid credentials.'
          } else if (error.response.status === 404) {
            errorMessage =
              'Not Found: The server can not find the requested resource.'
          } else if (error.response.status === 500) {
            errorMessage = `Internal Server Error: The server has encountered a situation.`
          }

          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: errorMessage
          })
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Error request:', error.request)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'No response received from server.'
          })
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error message:', error.message)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Request error: ${error.message}`
          })
        }
      }
    }
  }

  const exportExcel = async excelData => {
    setLoader(true)
    const newExcelData = excelData?.map(
      (
        {
          Project_code,
          Supplier_code,
          Part_no,
          Part_description,
          Availing_PLI_Incentive_Yes_No,
          TA_certified_dva_percentage,
          // PLI_claim_effective_date,
          PLI_claim_effective_date_dd,
          PLI_claim_effective_date_mm,
          PLI_claim_effective_date_yyyy,
          // Financial_year,
          // Quarter
          TMLBSL_approve_or_reject,
          TMLBSL_Remark,
          // Purchase_Remark,
          // Purchase_approve_or_reject,
          PMT_approve_or_reject,
          PMT_Remark
        },
        id
      ) => {
        let obj = {
          id,
          Project_code,
          Supplier_code,
          Part_no,
          Part_description,
          Availing_PLI_Incentive_Yes_No,
          TA_certified_dva_percentage,
          // PLI_claim_effective_date,
          PLI_claim_effective_date_dd,
          PLI_claim_effective_date_mm,
          PLI_claim_effective_date_yyyy,
          // Financial_year,
          // Quarter
          TMLBSL_approve_or_reject,
          TMLBSL_Remark,
          // Purchase_Remark,
          // Purchase_approve_or_reject,
          PMT_approve_or_reject,
          PMT_Remark
        }
        return obj
      }
    )

    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(newExcelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    setLoader(false)
    FileSaver.saveAs(data, `${Bc.value}${fileExt}`)
  }

  const baseDefaultColumns = [
    {
      width: 130,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Vendor Code',
      // field: "Supplier_Code",
      field: 'Supplier_code',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: "Plant code",
    //   field: "Plant_code",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: 'Part No.',
      // field: "Part_number",
      field: 'Part_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 210,
      title: 'Part Description',
      // field: "Part_Description",
      field: 'Part_description',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: "TML Part No. for eligible AAT Component",
    //   // field: "TML Part No. for eligible AAT Component",
    //   field: "Selling_Price",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: 'Availing PLI Incentive (Yes/No)',
      // field: "Availing PLI Incentive (Yes/No)",
      // field : "Availing_PLI_Incentive",
      field: 'Availing_PLI_Incentive_Yes_No',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   // title: "Date post which PLI incentives will be claimed",
    //   // field: "Date post which PLI incentives will be claimed",
    //   title: 'PLI Claim Effective Date',
    //   field: 'PLI_claim_effective_date',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date (DD)',
      field: 'PLI_claim_effective_date_dd',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date (MM)',
      field: 'PLI_claim_effective_date_mm',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date (YYYY)',
      field: 'PLI_claim_effective_date_yyyy',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'TA Certified DVA %',
      // field: "TA certified DVA % ",
      field: 'TA_certified_dva_percentage',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 180,
      title: 'TMLBSL Approve/Reject',
      field: 'TMLBSL_approve_or_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'TMLBSL Remark',
      field: 'TMLBSL_Remark',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 180,
    //   title: 'Purchase Remark',
    //   field: 'Purchase_Remark',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 180,
    //   title: 'Purchase Approve/Reject',
    //   field: 'Purchase_approve_or_reject',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 180,
      title: 'PMT Approve/Reject',
      field: 'PMT_approve_or_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'PMT Remark',
      field: 'PMT_Remark',
      enableSearch: true,
      enableFilter: true
    }

    // {
    //   width: 200,
    //   title: 'Financial Year',
    //   // field: "Financial Year",
    //   field: 'Financial_year',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 140,
    //   title: 'Quarter',
    //   field: 'Quarter',
    //   enableSearch: true,
    //   enableFilter: true
    // }
    // {
    //   width: 140,
    //   title: "MPFD",
    //   field: "MPFD",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 200,
    //   title: "Broad Description of parts imported by our suppliers",
    //   field: "Broad_Description_of_parts_imported_by_our_suppliers",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: "Local Content",
    //   field: "Local_Content",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 90,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 140,
    //   title : "Financial Year",
    //   field : "Financial year",
    //   enableSearch : true,
    //   enableFilter : true,
    // },
    // {
    //   width: 150,
    //   title: "T2 Import INR",
    //   field: "T2_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Exception",
    //   field: "Exception",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Exception Classification ",
    //   field: "Exception_Classification",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Total Import INR",
    //   field: "Total_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Explanation",
    //   field: "Explanation",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
  ]
  const baseDefaultColumnsGDC = [
    {
      width: 130,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Vendor Code',
      field: 'Supplier_code',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: "Plant code",
    //   field: "Plant_code",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: 'Part No.',
      field: 'Part_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 210,
      title: 'Part Description',
      field: 'Part_description',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: 'TML Part No. for eligible AAT Component',
    //   // field: "TML Part No. for eligible AAT Component",
    //   field: 'Selling_Price',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 200,
      title: 'Availing PLI Incentive (Yes/No)',
      // field: "Availing PLI Incentive (Yes/No)",
      field: 'Availing_PLI_Incentive_Yes_No',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: 'Date post which PLI incentives will be claimed',
    //   // field: "Date post which PLI incentives will be claimed",
    //   field: 'PLI_claim_effective_date',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date (DD)',
      field: 'PLI_claim_effective_date_dd',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date (MM)',
      field: 'PLI_claim_effective_date_mm',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date (YYYY)',
      field: 'PLI_claim_effective_date_yyyy',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'TA certified DVA %',
      // field: "TA certified DVA % ",
      field: 'TA_certified_dva_percentage',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'TMLBSL Approve/Reject',
      field: 'TMLBSL_approve_or_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'TMLBSL Remark',
      field: 'TMLBSL_Remark',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 180,
    //   title: 'Purchase Remark',
    //   field: 'Purchase_Remark',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 180,
    //   title: 'Purchase Approve/Reject',
    //   field: 'Purchase_approve_or_reject',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 180,
      title: 'PMT Remark',
      field: 'PMT_Remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'PMT Approve/Reject',
      field: 'PMT_approve_or_reject',
      enableSearch: true,
      enableFilter: true
    }
    // {
    //   width: 200,
    //   title: 'Financial Year',
    //   // field: "Financial Year",
    //   field: 'Financial_year',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 140,
    //   title: 'Quarter',
    //   field: 'Quarter',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 140,
    //   title: 'MPFD',
    //   field: 'MPFD',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 140,
    //   title: 'CA_SA',
    //   field: 'CA_SA',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 140,
    //   title: 'UDIN',
    //   field: 'UDIN',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 140,
    //   title: 'DVA_certifying_agency',
    //   field: 'DVA_certifying_agency',
    //   enableSearch: true,
    //   enableFilter: true
    // }
    // {
    //   width: 200,
    //   title: "Broad Description of parts imported by our suppliers",
    //   field: "Broad_Description_of_parts_imported_by_our_suppliers",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: "Local Content",
    //   field: "Local_Content",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 90,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 140,
    //   title : "Financial Year",
    //   field : "Financial year",
    //   enableSearch : true,
    //   enableFilter : true,
    // },
    // {
    //   width: 150,
    //   title: "T2 Import INR",
    //   field: "T2_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Exception",
    //   field: "Exception",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Exception Classification ",
    //   field: "Exception_Classification",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Total Import INR",
    //   field: "Total_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Explanation",
    //   field: "Explanation",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
  ]

  const goNextForm = async () => {
    let url
    let urlMethod

    if (userRole.includes('GDC')) {
      url = 'VendorMISProgress'
      urlMethod = 'PUT'
    } else if (userRole.includes('Vendor') || userRole.includes('Vendor-CV')) {
      // url = 'VendorMISProgress'
      url = 'SupplierUnderPLISubmissionstatus'
      urlMethod = 'POST'
    } else {
      url = 'VendorMISProgress'
      urlMethod = 'POST'
    }

    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      Vendor_underPLI_submitted_Date: formattedDate
    }

    setLoader(true)
    try {
      const response = await axios({
        method: urlMethod,
        url: `${baseUrl}${url}/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: apiFormatedData
      })

      // Check if the response status indicates success (200 or 201)
      if (response.status === 200 || response.status === 201) {
        setLoader(false)
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data is saved Successfully!'
        })
        history.push('/')
      } else {
        setLoader(false)
        throw new Error('Unexpected response status')
      }
    } catch (error) {
      setLoader(false)
      let errorMessage = 'An error occurred'
      if (error.response) {
        // Handle specific error codes
        switch (error.response.status) {
          case 400:
            // errorMessage = 'Bad Request'
            errorMessage = error?.response?.data?.error
            break
          case 401:
            // errorMessage = 'Unauthorized'
            errorMessage = error?.response?.data?.error
            break
          case 403:
            // errorMessage = 'Forbidden'
            errorMessage = error?.response?.data?.error
            break
          case 404:
            // errorMessage = 'Not Found'
            errorMessage = error?.response?.data?.error
            break
          case 500:
            // errorMessage = 'Internal Server Error'
            errorMessage = error?.response?.data?.error
            break
          default:
            // errorMessage = `Error: ${error.response.statusText}`
            errorMessage = error?.response?.data?.error
        }
      } else if (error.request) {
        // Handle cases where no response was received
        errorMessage = 'No response received from the server'
      } else {
        // Handle other errors
        // errorMessage = `Error: ${error.message}`
        errorMessage = error?.response?.data?.error
      }

      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: errorMessage
      })
    }
  }

  const renderHTML = () => (
    <div className={styles.formGroup}>
      {loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )}
      <Grid
        container
        spacing={1}
        style={{ display: 'flex', justifyContent: 'left' }}
        columns={12}
      >
        <Grid
          item
          // sm={12}
          md={3}
          lg={3}
          className={clsx(styles.alignCenter)}
        >
          <div
            className={styles.rowDiv}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
              className={clsx(styles.lableDiv)}
              style={{ width: '51%', display: 'flex', alignItems: 'center' }}
            >
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Project_code'
                options={projectCodeArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                // value={projectCode}
                value={selectedProjectCode}
                defaultValue={latestProject}
                isMulti={false}
                isClearable
                onChange={selectedOption => {
                  setProjectCode(selectedOption)
                  handleDropdownChange(selectedOption)
                  setSelectedProjectCode(selectedOption)
                  console.log('selectedOP: ', selectedOption)
                  GetSupplierCodeAPI(selectedOption)
                  setBC(null); // Reset supplier value
                  // getData(selectedOption)
                  setBcArray([]); // Clear supplier array
                }}
              />
            </div>
          </div>
        </Grid>

        <Grid item md={3} lg={3} className={clsx(styles.alignCenter)}>
          <div
            className={styles.rowDiv}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
              className={clsx(styles.lableDiv)}
              style={{ width: '30%', display: 'flex', alignItems: 'center' }}
            >
              <label className={styles.label}>Supplier</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='bc'
                options={bcArray}
                isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                isClearable
                // onChange={e => {
                //   setBC(e)
                // }}
                onChange={selectedOption => handleDropdownChange(selectedOption, 'Supplier')}
              />
            </div>
          </div>
        </Grid>

        {/* Project Desc. View */}
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            className={styles.rowDiv}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div className={clsx(styles.lableDiv)} style={{ width: '30%' }}>
              <label className={styles.label}>Project Desc.</label>
            </div>
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                value={projectDescription && projectDescription}
                name='projDesc'
                inputProps={{
                  'data-testid': 'remark-input'
                }}
                onChange={selectedOption =>
                  handleDropdownChange(selectedOption)
                }
                // eslint-disable-next-line react/jsx-boolean-value
                isDisabled={true}
              />
            </div>
          </div>
        </Grid>

        <Grid item md={2} lg={2} sm={2} style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center'
            }}
          >
            <AuthChecker operation='search'>
              {isAuthorized => (
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={showTable}
                  disabled={!isAuthorized}
                  data-testid='confirm-action'
                >
                  Show Data
                </Button>
              )}
            </AuthChecker>
            {flag && (
              <AuthChecker operation='search'>
                {isAuthorized => (
                  <Button
                    className={clsx(
                      styles.actionButton,
                      styles.primaryActionButton
                    )}
                    variant='primary'
                    onClick={() => exportExcel(tableArray)}
                    disabled={!isAuthorized}
                    data-testid='confirm-action'
                    style={{ marginLeft: '5px' }}
                  >
                    Download
                  </Button>
                )}
              </AuthChecker>
            )}

            {!auth.userRole.includes('Vendor') ? (
              <AuthChecker operation='search'>
                {isAuthorized => (
                  <Button
                    className={clsx(
                      styles.actionButton,
                      styles.primaryActionButton
                    )}
                    style={{
                      marginRight: '12px !important',
                      marginLeft: '5px'
                    }}
                    variant='primary'
                    onClick={() => {
                      getAllData()
                    }}
                    disabled={!isAuthorized}
                    data-testid='confirm-action'
                  >
                    Show All
                  </Button>
                )}
              </AuthChecker>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </div>
  )

  const downloadFile = async () => {
    const body = {
      Supplier_code: Bc?.value,
      Project_code: projectCode?.value
    }
    setLoader(true)
    try {
      // const response = await axios.post(`${baseUrl}SupplierDeclaration_PDFGen/`, body, {
      const response = await axios.post(
        `${baseUrl}SupplierUnderPLI_DOCX/`,
        body,
        {
          headers: {
            'Content-Type': 'application/json'
          }
          // responseType: 'blob', // Ensure axios returns the response as a blob for file download
        }
      )

      const success = response.status >= 200 && response.status < 300 // Check if the response status is 2xx

      // Handling the file download
      // const blob = new Blob([response.data], { type: 'application/pdf' });
      // const fileURL = window.URL.createObjectURL(blob);

      // let filename = response.headers['x-filename']
      // console.log('Response header: ', response.headers['x-filename'])
      // console.log('File name: ', filename)
      const blob = response.data?.file_url
      console.log('file from pli: ', response)

      const alink = document.createElement('a')
      alink.href = blob
      alink.download = `${projectCode?.value}_${Bc?.value}_${userName}.pdf`
      // alink.click();
      document.body.appendChild(alink)
      alink.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      )
      document.body.removeChild(alink)

      if (success) {
        setLoader(false)
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'File is successfully downloaded'
        })
      }
    } catch (error) {
      setLoader(false)
      if (error.response) {
        const { status } = error.response

        if (status === 400) {
          console.error(
            'Bad Request: Invalid data provided.',
            error.response.data
          )
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Bad request. Please check the supplied data.'
          })
        } else if (status === 401) {
          console.error('Unauthorized: Access is denied.', error.response.data)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Unauthorized. Please login to access this resource.'
          })
        } else if (status === 500) {
          console.error(
            'Server Error: An error occurred on the server.',
            error.response.data
          )
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Server error. Please try again later.'
          })
        } else {
          console.error('Error:', error.response.data)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'An unexpected error occurred.'
          })
        }
      } else {
        console.error('Network Error:', error.message)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Network error. Please check your connection.'
        })
      }
    }
  }

  const Uploadcertification = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.pdf')
    input.click()

    input.onchange = async e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('Supplier_code', Bc?.value)
      formData.append('Project_code', projectCode?.value)

      setLoader(true)
      try {
        const response = await axios.post(
          // `${baseUrl}SupplierDeclarationPdfUploadToS3Bucket/`,
          `${baseUrl}SupplierDeclarationPdfUploadToS3Bucketnew/`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )

        if (response.status === 200 || response.status === 201) {
          setLoader(false)
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Certificate Uploaded Successfully!'
          })
        } else {
          setLoader(false)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: response.data?.url || `Error: ${response.statusText}`
          })
        }
      } catch (error) {
        setLoader(false)
        let errorMessage = 'An error occurred'

        if (error.response) {
          switch (error.response.status) {
            case 400:
              errorMessage = 'Bad Request'
              break
            case 401:
              errorMessage = 'Unauthorized'
              break
            case 404:
              errorMessage = 'Not Found'
              break
            case 500:
              errorMessage = 'Internal Server Error'
              break
            default:
              errorMessage = `Error: ${error.response.statusText}`
          }
        } else if (error.request) {
          errorMessage = 'No response received from the server'
        } else {
          errorMessage = `Error: ${error.message}`
        }

        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Upload Failed. Reason: ${errorMessage}`
        })
      }
    }
  }

  const Uploadannexure = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.pdf')
    input.click()

    input.onchange = async e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('Supplier_code', Bc?.value)
      formData.append('Project_code', projectCode?.value)

      setLoader(true)
      try {
        const response = await axios.post(
          `${baseUrl}SupplierDeclarationAnnexureUploadToS3Bucket/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        if (response.status !== 201) {
          setLoader(false)
          const errorMessage = `Failed to upload file: ${response.statusText}`
          showPopup({
            type: MESSAGE_TYPE_ANNEXURE.FAILURE,
            contextText: MESSAGE_TYPE_ANNEXURE.FAILURE,
            info: MESSAGE_TYPE_ANNEXURE.FAILURE
          })
          throw new Error(errorMessage)
        }

        const responseJson = response.data
        console.log(responseJson)

        if (responseJson) {
          setLoader(false)
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: 'Success',
            info: 'Document Submitted Successfully!'
          })
        }
      } catch (error) {
        setLoader(false)
        let errorMessage = 'An error occurred'

        if (error.response) {
          switch (error.response.status) {
            case 400:
              errorMessage = 'Bad Request'
              break
            case 401:
              errorMessage = 'Unauthorized'
              break
            case 404:
              errorMessage = 'Not Found'
              break
            case 500:
              errorMessage = 'Internal Server Error'
              break
            default:
              errorMessage = `Error: ${error.response.statusText}`
          }
        } else if (error.request) {
          errorMessage = 'No response received from the server'
        } else {
          errorMessage = `Error: ${error.message}`
        }
        console.log('error:', errorMessage)
        showPopup({
          type: MESSAGE_TYPE_ANNEXURE.FAILURE,
          contextText: MESSAGE_TYPE_ANNEXURE.FAILURE,
          info: errorMessage
        })
      }
    }
  }

  const secondaryActions = [
    {
      name: 'Upload',
      authOperation: 'upload',
      shouldEnable: () => submitButtonFlag(),
      actionFn: selected => {
        uploadDocument()
      },
      customClass: ''
    },
    {
      name: 'Generate Certificate',
      authOperation: 'submit',
      shouldEnable: () => submitButtonFlag(),
      actionFn: selected => {
        downloadFile()
      },
      customClass: ''
    },
    {
      name: 'Upload Certificate',
      authOperation: 'uploadCertificate',
      shouldEnable: () => submitButtonFlag(),
      actionFn: selected => {
        Uploadcertification()
      },
      customClass: ''
    },
    {
      name: 'Other Documents',
      authOperation: 'uploadAnnexure',
      shouldEnable: () => submitButtonFlag(),
      actionFn: selected => {
        Uploadannexure()
      },
      customClass: ''
    },
    {
      name: 'Download Certificate',
      authOperation: 'download',
      shouldEnable: selected => true,
      actionFn: selected => {
        Uploadannexure()
      },
      customClass: ''
    },
    {
      name: 'Download Documents',
      authOperation: 'download',
      shouldEnable: selected => true,
      actionFn: selected => {
        Uploadannexure()
      },
      customClass: ''
    }
  ]

  const handleSecondaryActionOnRole = () => {
    if (auth.userRole.includes('admin')) {
      return secondaryActions
    }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL')) {
      return secondaryActions.filter(
        item =>
          item.name === 'Download Documents' ||
          item.name === 'Download Certificate' ||
          item.name === 'Upload'
      )
    }
    if (auth.userRole.includes('Vendor')) {
      return secondaryActions.filter(
        item =>
          item.name !== 'Download Documents' &&
          item.name !== 'Download Certificate'
      )
    }
    return secondaryActions
  }

  // Re-Submission_awaited
  /* 
    true = enable
    false = disable
  */
  function submitButtonFlag () {
    const Vendor_underPLI_submitted_status =
      pliSubmissionStatus?.Vendor_underPLI_submitted_status

    let buttonFlag
    if (
      Vendor_underPLI_submitted_status === 'Re-Submission_awaited' ||
      Vendor_underPLI_submitted_status === 'Not submitted'
    ) {
      buttonFlag = true
    }
    // else if (TMLBSLSubmitStatus === 'Not submitted' && PurchaseSubmitStatus === 'Not submitted') {
    //   buttonFlag = true
    // }
    else {
      buttonFlag = false
    }
    return buttonFlag
  }

  const enableFlag = submitButtonFlag()
  console.log('Flag: submitFlag: ', enableFlag)

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div style={{ width: '100%' }}>
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomTab
                      title='Supplier PLI Declaration'
                      withOutCount
                      isSelected
                    />
                  </div>

                  {/* <StepBar1 enable={5} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>

        {flag && (
          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
            <Table
              columns={
                !auth.userRole.includes('Vendor')
                  ? [...baseDefaultColumnsGDC]
                  : [...baseDefaultColumns]
              }
              defaultColumns={
                !auth.userRole.includes('Vendor')
                  ? [...baseDefaultColumnsGDC]
                  : [...baseDefaultColumns]
              }
              rowHeight={38}
              rows={tableArray}
              primaryAction={{
                name: 'Submit Data',
                authOperation: 'submit',
                // shouldEnable: () => true,
                shouldEnable: () => submitButtonFlag(),
                actionFn: handleOpenFormModal
              }}
              secondaryActions={handleSecondaryActionOnRole()}
              setAsnCount={setCount}
              isDataLoading={false}
              actionButtons
              onRowSelect={e => {}}
              removeRowSelection
            />
          </div>
        )}
      </div>
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
    </>
  )
}
function ResetModalF ({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Submit
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be submitted.</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal ({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleClose()
              goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(VendarData, RESOURCE_TYPE.PROJECT)
